import { match } from "ts-pattern";
import classNames from "classnames";

import { Spinner } from "../../../components/common";

export type ButtonType =
  | "primary"
  | "secondary"
  | "secondary-outline"
  | "tertiary"
  | "clear"
  | "critical";
export type ButtonSize = "large" | "medium" | "small";

type Props = {
  buttonType: ButtonType;
  buttonSize?: ButtonSize;
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
};

const getBackground = (buttonType: ButtonType) =>
  match(buttonType)
    .with("primary", () => "bg-greyscale-800")
    .with("secondary-outline", () => "bg-gray-900")
    .with(
      "critical",
      () =>
        "bg-gradient-to-r from-attention-btn-bg-left to-attention-btn-bg-right",
    )
    .with("tertiary", () => "bg-default-gray")
    .with("clear", () => "bg-transparent")
    .otherwise(() => "bg-main");

const getBorder = (buttonType: ButtonType) =>
  match(buttonType)
    .with("primary", () => "bg-transparent")
    .with("secondary", () => "bg-zinc-700")
    .with("secondary-outline", () => "bg-zinc-700")
    .with("clear", () => "bg-transparent")
    .with(
      "critical",
      () =>
        "bg-gradient-to-r from-attention-btn-border-left to-attention-btn-border-right",
    )
    .otherwise(() => "bg-main");

const getTextColor = (buttonType: ButtonType) =>
  match(buttonType)
    .with("critical", () => "text-black")
    .with("clear", () => "text-violet-300")
    .otherwise(() => "text-btn-label");

const getSize = (size: ButtonSize = "large") =>
  match(size)
    .with("large", () => "py-4 px-3 gap-4 text-xl")
    .with("medium", () => "py-3 px-4 gap-3 text-base")
    .with("small", () => "py-2 px-3 gap-1 text-xs")
    .otherwise(() => "py-3 px-4 gap-3 text-base");

const getFontWeight = (buttonType: ButtonType) =>
  match(buttonType)
    .with("secondary-outline", () => "font-normal")
    .otherwise(() => "font-bold");

export const TopButton = ({
  buttonType,
  buttonSize,
  text,
  icon,
  loading,
  onClick,
  disabled,
  block = true,
}: Props) => {
  return (
    <div
      className={`flex-1  ${block ? "w-full" : "w-fit"} h-fit ${getBorder(
        buttonType,
      )} overflow-hidden rounded-xl p-[1px] gap-2 `}
    >
      <button
        className={classNames(
          getTextColor(buttonType),
          getBackground(buttonType),
          getSize(buttonSize),
          getFontWeight(buttonType),
          "flex h-full w-full items-center justify-center rounded-xl font-poppins flex-col",
        )}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {loading ? (
          <Spinner size="small" />
        ) : (
          <>
            {icon}
            {text}
          </>
        )}
      </button>
    </div>
  );
};
