import WebComponent from "@khmyznikov/pwa-install/dist/pwa-install.react.js";
import {
  PWAPromptEvent,
  usePWAInstallContext,
} from "../../../providers/PWAInstallProvider";
import { useEffect, useRef } from "react";
import { match } from "ts-pattern";
import { isAndroid, isIOS } from "react-device-detect";
import typewriter, { OS } from "../../../typewriter/segment";

type PWAElement = {
  addEventListener: (type: string, listener: EventListener) => void;
  removeEventListener: (type: string, listener: EventListener) => void;
  showDialog: (show: boolean) => void;
  install: () => void;
  externalPromptEvent: PWAPromptEvent;
  isUnderStandaloneMode: boolean;
};

export const PWAInstall = () => {
  const pwaInstallRef = useRef<PWAElement | null>(null);
  const { event, setInstall, setShowPrompt, setIsAppInstalled } =
    usePWAInstallContext();
  useEffect(() => {
    if (event && pwaInstallRef.current) {
      pwaInstallRef.current.externalPromptEvent = event;
    }
  }, [event]);

  useEffect(() => {
    if (setIsAppInstalled && pwaInstallRef.current) {
      setIsAppInstalled(pwaInstallRef.current.isUnderStandaloneMode);
    }
  }, [setIsAppInstalled]);

  useEffect(() => {
    if (setInstall && pwaInstallRef.current && event) {
      setInstall(() => () => {
        if (event) {
          event.prompt().then((choice) => {
            if (choice.outcome === "dismissed") {
              localStorage.removeItem("pwaPrompt");
            } else {
              const os: OS = match({ isIOS, isAndroid })
                .with({ isIOS: true }, () => OS.Ios)
                .with({ isAndroid: true }, () => OS.Android)
                .otherwise(() => OS.Web);
              typewriter.appInstalled({ OS: os });

              if (setIsAppInstalled) {
                setIsAppInstalled(true);
              }
            }
          });
        }
      });
    }
  }, [event, setInstall, setIsAppInstalled]);

  useEffect(() => {
    if (setShowPrompt && pwaInstallRef.current) {
      setShowPrompt(() => () => {
        pwaInstallRef.current?.showDialog(true);
      });
    }
  }, [setShowPrompt]);

  return <WebComponent ref={pwaInstallRef} />;
};
