import { useTranslate } from "@tolgee/react";
import { GradientButton } from "../../../components/core";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

type Props = {
  onSubmit: () => void;
};

const SubmitFooter = ({ onSubmit }: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [isKeyboardHidden, setIsKeyboardHidden] = useState<boolean>(
    window.innerHeight / window.screen.availHeight > 0.6,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsKeyboardHidden(window.innerHeight / window.screen.availHeight > 0.6);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div
      className={`fixed bottom-0 w-full flex px-4 pt-3 ${
        isKeyboardHidden ? "pb-8" : "pb-3"
      } bg-gray-900 border-t border-zinc-700 justify-start items-start gap-2 inline-flex`}
    >
      <div className="grow shrink basis-0 h-12 justify-start items-start flex">
        <GradientButton
          buttonType="clear"
          buttonSize="medium"
          text={t("cancel")}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="grow shrink basis-0 h-12 justify-start items-start flex">
        <GradientButton
          buttonType="primary"
          buttonSize="medium"
          text={t("submit")}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SubmitFooter;
