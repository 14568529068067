import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@frontend/lyng/index.css";
import "./index.css";
import { CareProvider } from "./providers";
import "./utils/intlOverride";
import { Authenticator } from "@frontend/lyng/Authenticator";
import PWAInstallProvider from "./providers/PWAInstallProvider";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <PWAInstallProvider>
      <Authenticator app="mobile">
        <CareProvider>
          <App />
        </CareProvider>
      </Authenticator>
    </PWAInstallProvider>
  </React.StrictMode>,
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(
      import.meta.env.MODE === "production"
        ? "/service-worker.js"
        : "/dev-sw.js?dev-sw",
      { type: import.meta.env.MODE === "production" ? "classic" : "module" },
    )
    .catch((err) => {
      console.log("Service worker registration failed:", err);
    });
} else {
  console.log("Service workers are not supported in this browser.");
}
