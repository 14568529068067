import { ChevronLeftOutline } from "../assets/svg/Chevron";
import { EyeClosed, EyeOpen } from "../assets/svg/Eye";
import LoadingSpinner from "../assets/svg/loading-spinner.svg?react";
import { zodResolver } from "@hookform/resolvers/zod";
import { signIn, signInWithRedirect } from "aws-amplify/auth";
import classNames from "classnames";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { match } from "ts-pattern";
import { z } from "zod";
import { AuthenticatorCard } from "./AuthenticatorCard";
import { AppType } from "./authenticatorRouter";

const FF_RVK_SAML_LOGIN = import.meta.env.VITE_FF_RVK_SAML_LOGIN === "true";
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const emailValidationSchema = z.object({
  email: z.string().regex(emailRegex, { message: "Invalid email" }),
});
type EmailFormValues = z.infer<typeof emailValidationSchema>;

const EmailForm = ({
  defaultValue,
  onSubmit,
}: {
  defaultValue: string | null;
  onSubmit: (email: string) => void;
}) => {
  const { register, handleSubmit, formState } = useForm<EmailFormValues>({
    resolver: zodResolver(emailValidationSchema),
    defaultValues: {
      email: defaultValue || "",
    },
  });

  return (
    <form
      className="space-y-6 p-4"
      action="#"
      method="POST"
      onSubmit={handleSubmit(({ email }) => onSubmit(email))}
      noValidate
    >
      <div>
        <label
          htmlFor="email"
          className="ml-4 block text-lg font-medium text-greyscale-900"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="username"
            {...register("email")}
            type="email"
            autoComplete="email"
            autoFocus
            className={classNames(
              "flex h-12 w-full items-center rounded-xl border-0 bg-primary-0 py-2 pl-4 text-lg text-gray-900 shadow-sm ring-1 ring-inset transition-colors focus:ring-2 focus:ring-inset",
              {
                "ring-greyscale-300 placeholder:text-greyscale-400 focus:ring-primary-600":
                  !formState.errors.email,
                "ring-red-300 placeholder:text-red-300 focus:ring-red-600":
                  formState.errors.email,
              },
            )}
          />
        </div>
        {formState.errors.email && (
          <div className="ml-4 mt-3 text-sm text-critical-800">
            {formState.errors.email.message}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <button
          type="submit"
          className="flex w-full items-center justify-center rounded-lg bg-primary-600 px-8 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:bg-primary-800"
        >
          Continue to log in
        </button>
      </div>
    </form>
  );
};

const passwordValidationSchema = z.object({
  password: z.string().min(1),
});

type PasswordFormValues = z.infer<typeof passwordValidationSchema>;

const PasswordForm = ({
  app,
  email,
  onSubmit,
  onBack,
}: {
  app: AppType;
  email: string;
  onSubmit: (password: string) => Promise<void>;
  onBack: () => void;
}) => {
  const [signInError, setSignInError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit, formState } = useForm<PasswordFormValues>({
    resolver: zodResolver(passwordValidationSchema),
    defaultValues: {
      password: "",
    },
  });

  const onSubmitInner: SubmitHandler<PasswordFormValues> = async (data) => {
    setSignInError(null);
    await onSubmit(data.password).catch((error) => {
      setSignInError(error.message);
    });
  };

  return (
    <form
      className="space-y-6 px-4 pb-4 pt-2"
      action="#"
      method="POST"
      onSubmit={handleSubmit(onSubmitInner)}
    >
      <button
        onClick={() => onBack()}
        type="button"
        className="flex items-center gap-2 pt-3"
      >
        <ChevronLeftOutline className="text-primary-600" />
        <div className="text-xl text-primary-700">{email}</div>
      </button>

      <div>
        <label
          htmlFor="password"
          className="ml-4 block text-lg font-medium text-greyscale-900"
        >
          Password
        </label>
        <div className="relative mt-2">
          <input
            id="password"
            {...register("password")}
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            autoFocus
            className={classNames(
              "flex h-12 w-full items-center rounded-xl border-0 bg-primary-0 py-2 pl-4 text-lg text-gray-900 shadow-sm ring-1 ring-inset transition-colors focus:ring-2 focus:ring-inset",
              {
                "ring-greyscale-300 placeholder:text-greyscale-400 focus:ring-primary-600":
                  !formState.errors.password,
                "ring-red-300 placeholder:text-red-300 focus:ring-red-600":
                  formState.errors.password,
              },
            )}
          />

          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              className="text-greyscale-600 hover:text-greyscale-400 active:text-greyscale-600"
            >
              {showPassword ? <EyeClosed /> : <EyeOpen />}
            </button>
          </div>
        </div>
        {signInError && (
          <div className="ml-4 mt-3 text-sm text-critical-800">
            {signInError}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <button
          type="submit"
          className="flex w-full items-center justify-center rounded-lg bg-primary-600 px-8 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:bg-primary-800"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? (
            <LoadingSpinner className="h-6 w-6" />
          ) : (
            "Log in to " + (app === "mobile" ? "app" : "office")
          )}
        </button>
        <Link
          to="/forgot-password"
          state={{ email }}
          className="flex w-full items-center justify-center rounded-lg bg-white px-8 py-3 text-lg font-semibold leading-6 text-primary-700 hover:text-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:text-primary-800"
        >
          Forgot password?
        </Link>
      </div>
    </form>
  );
};

type EmailStep = { type: "email"; maybeEmail?: string };
type PasswordStep = { type: "password"; email: string };

type Props = {
  app: AppType;
};

type SignInStep = EmailStep | PasswordStep;
export const SignIn = ({ app }: Props) => {
  const { state: locationState } = useLocation();

  const [signInStep, setSignInStep] = useState<SignInStep>({
    type: "email",
    maybeEmail: locationState?.email,
  });

  const onEmailContinue = (email: string) => {
    const gvEmails = import.meta.env.VITE_GV_EMAILS || "";
    const emails = gvEmails.split(",");
    const providerName = `reykjavik-identity-provider-${import.meta.env.VITE_TIER}`;
    if (
      (email.split("@")[1].toLowerCase() === "reykjavik.is" &&
        FF_RVK_SAML_LOGIN) ||
      emails.includes(email)
    ) {
      return signInWithRedirect({
        provider: {
          custom: providerName,
        },
      });
    }
    setSignInStep({ type: "password", email });
  };

  const onSubmit = async (email: string, password: string) => {
    await signIn({ username: email, password }).then((result) => {
      if (result.nextStep.signInStep !== "DONE") {
        return Promise.reject("Something went wrong");
      }
    });
  };

  return (
    <>
      <AuthenticatorCard>
        {match(signInStep)
          .with({ type: "email" }, ({ maybeEmail }) => (
            <EmailForm
              defaultValue={maybeEmail || null}
              onSubmit={onEmailContinue}
            />
          ))
          .with({ type: "password" }, ({ email }) => (
            <PasswordForm
              app={app}
              email={email}
              onSubmit={async (password) => {
                await onSubmit(email, password);
              }}
              onBack={() => {
                setSignInStep({ type: "email", maybeEmail: email });
              }}
            />
          ))
          .exhaustive()}
      </AuthenticatorCard>

      {signInStep.type === "email" && (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <p className="inline-block w-full text-center text-lg font-normal text-white">
            {"Don't have an account? "}
            <a
              href="https://signup.dala.care"
              className="font-medium text-primary-300 hover:underline active:text-primary-400"
            >
              Sign up
            </a>
          </p>
        </div>
      )}
    </>
  );
};
