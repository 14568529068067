import { Headline } from "../typography";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

export const Error = () => {
  const error = useRouteError();
  Sentry.captureException(error);
  console.log(error);
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center gap-2 pb-52">
        <Headline size="l">Whoops! Something went wrong</Headline>
        <p>Please try again or contact support</p>
      </div>
    </div>
  );
};
