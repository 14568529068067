import { useTolgee, useTranslate } from "@tolgee/react";

import { SlideOver } from "../../components/core";
import { applyCase } from "beygla";
import { Button } from "@frontend/lyng/button";
import ThankYouLeaves from "../../assets//thank-you-leaves.svg?react";

type Props = {
  name: string;
  onDone: () => void;
};

export const ThankYouModal = ({ name, onDone }: Props) => {
  const { t } = useTranslate();
  const tolgee = useTolgee();

  const fixNameForIcelandicCasing = (
    name: string,
    language: string,
  ): string => {
    if (language === "is") return applyCase("þf", name);
    return name;
  };

  return (
    <SlideOver onClose={onDone}>
      <div className="absolute z-0 flex h-full w-full justify-center bg-secondary-900">
        <div className="absolute z-10 flex w-full flex-1 flex-col items-stretch top-20">
          <div className="flex justify-end">
            <ThankYouLeaves className="relative right-0 top-10" />
          </div>
          <div className="flex h-full flex-1 grow flex-col items-stretch px-8 text-3xl font-bold text-title">
            <div>{t("postClockOutGreeting.first")}</div>
            <div>{t("postClockOutGreeting.second")}</div>
            <div>
              {t("postClockOutGreeting.third", {
                name: fixNameForIcelandicCasing(
                  name,
                  tolgee.getLanguage() ?? "en",
                ),
              })}
            </div>
          </div>
          <div className="fixed bottom-0 h-24 w-full grow justify-center items-center px-6">
            <Button
              className="w-full z-50"
              type="button"
              size="lg"
              variant="tertiary"
              text={t("close")}
              onClick={onDone}
            />
          </div>
        </div>
      </div>
    </SlideOver>
  );
};
