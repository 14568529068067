import { UseTranslateResult } from "@tolgee/react";
import { useCallback } from "react";
import { match } from "ts-pattern";

export const useTranslateSentiment = (t: UseTranslateResult["t"]) => {
  return useCallback(
    (key: "neutral" | "mixed" | "positive" | "negative") => {
      return match(key)
        .with("neutral", () => t("sentiment.neutral"))
        .with("mixed", () => t("sentiment.mixed"))
        .with("positive", () => t("sentiment.positive"))
        .with("negative", () => t("sentiment.negative"))
        .exhaustive();
    },
    [t],
  );
};
