import classNames from "classnames";
import { match } from "ts-pattern";

type Props = {
  size?: "regular" | "small";
};

export const Spinner = ({ size = "regular" }: Props) => (
  <div className="flex h-full w-full items-center justify-center">
    <div
      className={classNames(
        "border-primary-700 animate-spin rounded-full border-solid border-t-transparent",
        match(size)
          .with("regular", () => "h-16 w-16 border-8")
          .with("small", () => "h-8 w-8 border-4")
          .exhaustive(),
      )}
    />
  </div>
);
