import { Label, Paragraph } from "../typography";
import React, { forwardRef, useState } from "react";
import { Schedule } from "../assets/icons/16/outline";
import { Edit } from "../assets/icons/16/outline";
import { SmallPill } from "../pill";
import { FeedLog } from "./types";
import { useTranslate } from "@tolgee/react";
import { Sentiment } from "../api/generated/graphql";
import { PillColors } from "../pill";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import { DateSettings, useDateFormatter } from "../utils/dateUtils";
import { Button } from "../button/Button";
import { useTranslateSentiment } from "../utils/translationUtils";

type Props = {
  log: FeedLog;
  onClick: (id: string, type: string) => void;
  dateSettings: DateSettings;
  className?: string;
};

type sentimentKey = "neutral" | "mixed" | "positive" | "negative";
const getDisplaySentiment = (
  sentiment: Sentiment | null,
): sentimentKey | null => {
  if (!sentiment) {
    return null;
  }
  const keyValuePairs: { key: sentimentKey; value: number }[] = [
    { key: "neutral", value: sentiment.neutral },
    { key: "mixed", value: sentiment.mixed },
    { key: "positive", value: sentiment.positive },
    { key: "negative", value: sentiment.negative },
  ];
  return keyValuePairs.reduce((prev, curr) =>
    curr.value > prev.value ? curr : prev,
  ).key;
};

const ActivitiesStatusSentence = ({ logItem }: { logItem: FeedLog }) => {
  const { t } = useTranslate();
  if (logItem.completedActivites === null || logItem.numberOfActivites === 0) {
    return;
  }

  let sentence = "";
  if (logItem.completedActivites === 0) {
    sentence = `${t("activityStatus.noActivitiesCompleted") ?? ""}`;
  } else if (logItem.numberOfActivites === logItem.completedActivites) {
    sentence = `${t("activityStatus.allActivitiesCompleted") ?? ""}`;
  } else {
    sentence = `${logItem.completedActivites}/${logItem.numberOfActivites} ${t("activityStatus.activitiesCompleted") ?? ""}`;
  }
  return (
    <Label size="xxs" className="pt-4">
      {sentence}
    </Label>
  );
};

const SentimentPill = ({ sentiment }: { sentiment: Sentiment | null }) => {
  const { t } = useTranslate();
  const translateSentiment = useTranslateSentiment(t);

  const displaySentiment = getDisplaySentiment(sentiment);

  if (!displaySentiment) {
    return null;
  }

  const color = match<sentimentKey, PillColors>(displaySentiment)
    .with("neutral", () => "blue")
    .with("mixed", () => "gray")
    .with("positive", () => "green")
    .with("negative", () => "red")
    .exhaustive();

  return (
    <SmallPill
      className="cursor-pointer"
      color={color}
      text={translateSentiment(displaySentiment)}
    />
  );
};

export const ActivitiesFeedCard = forwardRef<HTMLDivElement, Props>(
  ({ log, dateSettings, onClick, className }, ref) => {
    const { t } = useTranslate();
    const { formatTime, formatDate } = useDateFormatter(dateSettings);
    const [showFullLog, setShowFullLog] = useState(false);

    function formatDateString(logDate: string) {
      const date = DateTime.fromISO(logDate);
      const today = DateTime.now();
      const yesterday = today.minus({ days: 1 });

      if (date.toISODate() === today.toISODate()) {
        return `${t("relativeTime.today")} ${formatTime(date)}`;
      } else if (date.toISODate() === yesterday.toISODate()) {
        return `${t("relativeTime.yesterday")} ${formatTime(date)}`;
      } else {
        return `${formatDate(date)} ${formatTime(date)}`;
      }
    }

    const handleCardClick = (log: FeedLog) => {
      const id = log.type === "VISIT" ? log.id : log.careRecipientId;
      onClick(id, log.type);
    };

    const toggleShowFullLog = (event: React.MouseEvent) => {
      event.stopPropagation();
      setShowFullLog(!showFullLog);
    };

    return (
      <div ref={ref} className={`max-w-96 flex-col inline-flex`}>
        <div className="h-9 items-center inline-flex">
          <div>
            {log.type === "VISIT" ? (
              <Schedule className="mx-2 text-greyscale-600" />
            ) : (
              <Edit className="mx-2 text-greyscale-600" />
            )}
          </div>
          <div className="ml-3">
            <Paragraph
              size="xs"
              type="secondary"
              className="dark:text-greyscale-300"
            >
              {log.type === "VISIT" ? t("reports.visit") : t("careLog")} -{" "}
              {log.logDate && formatDateString(log.logDate)}
            </Paragraph>
          </div>
        </div>
        <div className="px-4 flex-col flex mr-1 ">
          <div
            className={`pl-3 pt-2 pb-8 border-l dark:border-greyscale-700 border-greyscale-200 inline-flex ${className}`}
          >
            <button
              className="grow shrink basis-0 px-5 pt-4 pb-5 bg-white dark:bg-greyscale-800 rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)] flex-col gap-2 hover:shadow-[0_2px_6px_-1px_rgba(0,0,0,0.2)]"
              onClick={() => handleCardClick(log)}
            >
              <div className="gap-1 flex flex-col items-start">
                <Paragraph
                  size="xs"
                  type="secondary"
                  className="dark:text-greyscale-300"
                >
                  {log.fullName}
                  {log.source === "FeedItems" ? " " + t("for") : ""}
                </Paragraph>
                {log.source === "FeedItems" && (
                  <Label
                    size="m"
                    className="cursor-pointer"
                  >{`${log.careRecipientName}`}</Label>
                )}
              </div>
              <div className="pt-4 flex-col items-start flex relative text-start">
                {log.type === "VISIT" && log.visitlogSentiment && (
                  <div className="gap-4 mb-4">
                    <SentimentPill sentiment={log.visitlogSentiment} />
                  </div>
                )}
                <Paragraph
                  size="s"
                  className={`block ${showFullLog ? "" : " overflow-hidden overflow-ellipsis max-h-48"}`}
                >
                  {log.log || t("noVisitNote")}
                </Paragraph>
                {log.log?.length && log.log?.length > 230 && !showFullLog ? (
                  <div className="!absolute flex right-0 bottom-[1px] ">
                    <div className=" flex w-4 bg-gradient-to-r from-transparent to-white dark:to-greyscale-800" />
                    <Button
                      size="md"
                      variant="tertiary"
                      className=" bg-white text-sm !p-0 rounded-none dark:bg-greyscale-800"
                      onClick={toggleShowFullLog}
                      text={t("seeMore") ?? ""}
                      disabled={false}
                    />
                  </div>
                ) : null}
                {showFullLog && (
                  <Button
                    size="md"
                    variant="tertiary"
                    className=" text-sm mt-4 !p-0 self-end"
                    onClick={toggleShowFullLog}
                    text={t("seeLess") ?? ""}
                    disabled={false}
                  />
                )}
                <ActivitiesStatusSentence logItem={log} />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  },
);
