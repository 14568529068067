export enum DateFormat {
  DdMmYyyy = "DD_MM_YYYY",
  MmDdYyyy = "MM_DD_YYYY",
}

export enum TimeFormat {
  AmPm = "AM_PM",
  TwentyFourHour = "TWENTY_FOUR_HOUR",
}

export enum Weekday {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}
