import { Amplify } from "aws-amplify";
import { AuthUser, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useCallback, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { match } from "ts-pattern";
import { AppType, authenticatorRouter } from "./authenticatorRouter";
import { Splash } from "../common/Splash";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

const userPoolId = import.meta.env.VITE_COGNITO_USER_POOL_ID;
const userPoolClientId = import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID;
const domain = import.meta.env.VITE_COGNITO_DOMAIN;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId,
      loginWith: {
        email: true,
        oauth: {
          domain,
          scopes: ["email", "openid", "profile"],
          redirectSignIn: [
            "http://localhost:3000/",
            "https://office.dev.dala.care/",
            "https://office.dala.care/",
            "https://app.dev.dala.care/",
            "https://app.dala.care/",
          ],
          redirectSignOut: [
            "http://localhost:3000/",
            "https://office.dev.dala.care/",
            "https://office.dala.care/",
            "https://app.dev.dala.care/",
            "https://app.dala.care/",
          ],
          responseType: "code",
        },
      },
    },
  },
});

/* eslint-disable-next-line no-restricted-globals */
const isVercelPreview = location.hostname.includes("vercel.app");
/* eslint-disable-next-line no-restricted-globals */
const isLocal = location.hostname.includes("localhost");

type DomainState = {
  tier: "dev" | "prod";
  isVercelPreview: boolean;
  isLocal: boolean;
};

const domainState: DomainState = {
  tier: import.meta.env.VITE_TIER as "dev" | "prod",
  isLocal,
  isVercelPreview,
};

match(domainState)
  .with({ isVercelPreview: false, isLocal: false, tier: "dev" }, () =>
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({
        domain: ".dev.dala.care",
        secure: true,
        sameSite: "none",
      }),
    ),
  )
  .with({ isVercelPreview: false, isLocal: false, tier: "prod" }, () =>
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({
        domain: ".dala.care",
        secure: true,
        sameSite: "none",
      }),
    ),
  )
  // for local dev and vercel preview
  .otherwise(() => {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
  });

function clearCookies() {
  cognitoUserPoolsTokenProvider.authTokenStore.getKeyValueStorage().clear();
  // Legacy cookie storage
  new CookieStorage().clear();
}

type AuthStateInitial = {
  type: "initial";
};
type AuthStateLoading = {
  type: "loading";
};
type AuthStateUser = {
  type: "user";
  user: AuthUser;
};
type AuthStateUnauthenticated = {
  type: "unauthenticated";
};
type AuthState =
  | AuthStateInitial
  | AuthStateLoading
  | AuthStateUser
  | AuthStateUnauthenticated;

type Props = {
  children: React.ReactNode;
  app: AppType;
};
export const Authenticator = ({ children, app }: Props) => {
  const [authState, setAuthState] = useState<AuthState>({
    type: "initial",
  });

  const getUser = useCallback(() => {
    setAuthState({
      type: "loading",
    });
    getCurrentUser()
      .then((user) => {
        setAuthState({
          type: "user",
          user,
        });
      })
      .catch(() => {
        setAuthState({
          type: "unauthenticated",
        });
      });
  }, []);

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen("auth", ({ payload }) => {
      match(payload.event)
        .with("signedIn", () => {
          getUser();
        })
        .with("signedOut", () => {
          clearCookies();
          setAuthState({ type: "unauthenticated" });
        })
        .with("tokenRefresh_failure", () => {
          clearCookies();
          setAuthState({ type: "unauthenticated" });
        })
        .with("signInWithRedirect", () => {
          getUser();
        })
        .with("signInWithRedirect_failure", () => {
          setAuthState({ type: "unauthenticated" });
        })
        .otherwise(() => {
          console.error("Unhandled auth event", payload.event);
        });
    });

    return () => {
      hubListenerCancelToken();
    };
  }, [getUser]);

  useEffect(() => {
    if (authState.type !== "initial") {
      return;
    }
    getUser();
  }, [authState.type, getUser]);

  return match(authState)
    .with({ type: "initial" }, () => <Splash />)
    .with({ type: "loading" }, () => <Splash />)
    .with({ type: "user" }, () => children)
    .with({ type: "unauthenticated" }, () => (
      <RouterProvider router={authenticatorRouter(app)} />
    ))
    .exhaustive();
};
