import * as Sentry from "@sentry/react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import { Error } from "../components/core/Error";

import { Layout, NotFound } from "../components/core";
import {
  CarePlan,
  ClockOut,
  ConfirmActivities,
  Contacts,
  Expenses,
  Mileage,
  NotCompleteReason,
  PastVisits,
  Profile,
  Schedule,
  VisitNote,
  VisitDetails,
} from "../pages";
import { VisitWizard } from "../pages/visits/VisitWizard";

export type RoutePages =
  | "schedule"
  | "profile"
  | "details"
  | "visitNote"
  | "confirmActivities"
  | "carePlan"
  | "contacts"
  | "mileage"
  | "notfound"
  | "expenses"
  | "reason"
  | "pastVisits"
  | "visitWizardNew"
  | "visitWizardUpdate";

const navigatorDefault = {
  canBack: false,
  showFooter: false,
  showNotificationsButton: false,
  showHeader: true,
  showTitle: true,
};

export const router = () => {
  const sentryCreateBrowserRouter: typeof createBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  return sentryCreateBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          errorElement: <Error />,
          children: [
            {
              path: "",
              element: <Schedule />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "schedule" as RoutePages,
                  showFooter: true,
                  showNotificationsButton: true,
                }),
              },
            },
            {
              path: "profile",
              element: <Profile />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "profile" as RoutePages,
                  showFooter: true,
                  showNotificationsButton: true,
                }),
              },
            },
            {
              path: "visit/new",
              element: <VisitWizard />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "visitWizardNew" as RoutePages,
                }),
              },
            },
            {
              path: "visit/:id/edit",
              element: <VisitWizard />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "visitWizardUpdate" as RoutePages,
                }),
              },
            },
            {
              path: "visit/:id/mileage",
              element: <Mileage />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "mileage" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id/expenses",
              element: <Expenses />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "expenses" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id",
              element: <VisitDetails />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "details" as RoutePages,
                  canBack: true,
                  showFooter: false,
                  showHeader: true,
                  showTitle: false,
                }),
              },
            },
            {
              path: "visit/:id/past-visits/:careRecipientId",
              element: <PastVisits />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "pastVisits" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id/careplan",
              element: <CarePlan />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "carePlan" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id/contacts/:careRecipientId",
              element: <Contacts />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "contacts" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id/clockout",
              element: <ClockOut />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "visitNote" as RoutePages,
                  canBack: true,
                }),
              },
            },
            {
              path: "visit/:id/visit-note",
              element: <VisitNote />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "visitNote" as RoutePages,
                }),
              },
            },
            {
              path: "visit/:id/confirm-activities",
              element: <ConfirmActivities />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "confirmActivities" as RoutePages,
                }),
              },
            },
            {
              path: "visit/:id/:activityId/reason",
              element: <NotCompleteReason />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "reason" as RoutePages,
                }),
              },
            },
            {
              path: "reset-password",
              element: <Navigate to="/" />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "notFound" as RoutePages,
                }),
              },
            },
            {
              path: "*",
              element: <NotFound />,
              handle: {
                navigator: () => ({
                  ...navigatorDefault,
                  route: "notFound" as RoutePages,
                  showHeader: false,
                }),
              },
            },
          ],
        },
      ],
    },
  ]);
};
