import { Duration, Subtext } from "@frontend/lyng/forms";
import { DateSettings } from "@frontend/lyng/utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import { DateTime } from "luxon";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

type Props = {
  startTime?: DateTime;
  dateSettings: DateSettings;
  errorMessage?: string;
};

export function VisitDuration<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  startTime,
  dateSettings,
  errorMessage,
  ...props
}: UseControllerProps<TFieldValues, TName> & Props) {
  const timeOptions = [15, 30, 60];
  const { t } = useTranslate();
  const { field } = useController(props);
  const actualStartTime = startTime ?? DateTime.now();
  const endsNextDay =
    actualStartTime.plus({ minutes: field.value }).day !== actualStartTime.day;

  return (
    <>
      <Duration
        {...{ ...props, timeOptions }}
        startTime={startTime}
        dateSettings={dateSettings}
        errorMessage={errorMessage}
      />
      {endsNextDay && (
        <Subtext className="px-6" text={t("visitDuration.endsNextDay")} />
      )}
    </>
  );
}
