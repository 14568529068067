import { Label } from "../../typography";

type Props = {
  label: string;
  labelFor?: string;
  children: React.ReactNode;
};
const FormGroup = ({ label, labelFor, children }: Props) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr_1fr] sm:pt-5">
      <Label size="m" htmlFor={labelFor} className="px-1 py-2.5">
        {label}
      </Label>
      {children}
    </div>
  );
};

const FormGroupStacked = ({ label, labelFor, children }: Props) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Label size="m" htmlFor={labelFor} className="px-4 pt-4">
        {label}
      </Label>
      {children}
    </div>
  );
};

export { FormGroup, FormGroupStacked };
