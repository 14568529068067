import { isToday } from "../../../utils/dateUtils";
import { DateTime } from "luxon";
type Props = {
  date: DateTime;
  shouldSticky: boolean;
};

export const VisitDay = ({ date, shouldSticky }: Props) => {
  const day = date.weekdayShort;
  const monthDay = date.day;
  const month = date.monthShort;
  const border = isToday(date)
    ? "border border-r-day-divider border-l-main border-y-main"
    : "bg-transparent";
  const sticky = shouldSticky ? "sticky" : "";
  return (
    <div className={`my-1 ${border} py-2`}>
      <div className={`${sticky} top-16 z-20`}>
        <div className="sticky z-20 my-2 ml-2 flex h-full w-16 flex-col items-center">
          <div className="text-2xl font-bold text-title">{monthDay}</div>
          <div className="text-xs text-title">{month}</div>
          <div className="text-xs text-subtitle opacity-100">{day}</div>
        </div>
      </div>
    </div>
  );
};
