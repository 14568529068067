import { useTranslate } from "@tolgee/react";
import { useApolloClient } from "@apollo/client";
import isiOS from "is-ios";

import { useCareContext } from "../../providers";
import LogoutIcon from "../../assets/logout.svg?react";
import { GradientButton } from "../../components/core";
import { logOut as logOutEvent } from "../../typewriter/segment";
import { signOut } from "aws-amplify/auth";
import {
  MobileNotificationsCard,
  MobileNotificationsInstructionsPrompt,
  NotificationsPrompt,
} from "@frontend/lyng/notifications";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { usePWAInstallContext } from "../../providers/PWAInstallProvider";
import { isCaregiver } from "../../utils/viewerUtils";
import { useNotificationsEnablePrompt } from "../../hooks/notificationsPrompt";
import { match } from "ts-pattern";

const InfoLine = ({ title, info }: { title: string; info: string }) => (
  <li className="flex flex-col gap-2">
    <p className="text-lg font-medium text-gray-300">{title}</p>
    <p className="text-lg font-light text-gray-300">{info}</p>
  </li>
);

const pwaWorkerAvailable = "serviceWorker" in navigator;
const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;

export const Profile = () => {
  const client = useApolloClient();
  const [show, setShow] = useState(false);
  const [permission, setPermission] = useState(
    isSupported() ? Notification.permission : "denied",
  );
  const { t } = useTranslate();
  const { install, showPrompt, isAppInstalled } = usePWAInstallContext();
  const {
    state: { viewer },
  } = useCareContext();
  const [enabledNotifications, setEnabledNotifications] = useState(false);

  const {
    shouldShowNotificationsPrompt,
    setShouldShowNotificationsPrompt,
    setAccepted,
    accepted,
  } = useNotificationsEnablePrompt();

  const logOut = async () => {
    await client.resetStore();
    localStorage.removeItem("token");
    await signOut();
    logOutEvent({});
  };

  const handleInstall = () => {
    if (isiOS) {
      if (showPrompt) {
        showPrompt();
      }
    } else {
      if (install) {
        install();
      }
    }
  };

  const registered = (v: boolean) => {
    setEnabledNotifications(v);
    setAccepted(true);
    setPermission(isSupported() ? Notification.permission : "denied");
  };

  useEffect(() => {
    if (!pwaWorkerAvailable) return;

    navigator.serviceWorker
      .getRegistration() //
      .then(async (registration) => {
        if (!registration || !registration.pushManager) return;

        const subscription = await registration.pushManager.getSubscription();

        setEnabledNotifications(!!subscription);
      });
  }, []);

  const data = [{ title: t("email"), info: viewer?.email ?? "" }];
  const allowNotifications = isCaregiver(viewer);

  const showNotificationCard = match({
    permission,
    pwaWorkerAvailable,
    allowNotifications,
    isAppInstalled,
    enabledNotifications,
    isMobile,
  })
    .with({ isMobile: true, isAppInstalled: false }, () => false)
    .with({ enabledNotifications: true }, () => false)
    .with({ pwaWorkerAvailable: false }, () => false)
    .with({ allowNotifications: false }, () => false)
    .with({ permission: "denied" }, () => false)
    .otherwise(() => true);

  return (
    <>
      <div className="mb-14 flex flex-col gap-8 p-4">
        <div className="flex flex-col items-center justify-center gap-4">
          <span className="inline-flex h-32 w-32 items-center justify-center rounded-full bg-gray-500">
            <span className="text-6xl font-medium leading-none text-white">
              {`${viewer?.firstName?.charAt(0).toUpperCase()}${viewer?.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            </span>
          </span>
          <h2 className="text-2xl font-bold text-gray-100">
            {`${viewer?.firstName} ${viewer?.lastName}`}
          </h2>
        </div>
        <ul className="flex flex-col gap-4">
          {data.map(({ title, info }) => (
            <InfoLine key={info} title={title} info={info} />
          ))}
        </ul>
        {!isAppInstalled && pwaWorkerAvailable && (
          <ul className="flex flex-col gap-2 p-4 bg-gray-900">
            <h2 className="text-lg font-bold text-gray-100">
              Add dala.care to home screen
            </h2>
            <p className="font-light text-gray-100 mb-2">
              This app is not intalled on your homescreen.
            </p>
            <GradientButton
              text={isiOS ? "Show me how to install" : "Install now"}
              buttonType="secondary-outline"
              buttonSize="small"
              onClick={handleInstall}
            />
          </ul>
        )}
        {showNotificationCard && (
          <>
            <MobileNotificationsCard
              vapidKey={viewer?.vapidKey}
              showInstructions={() => {
                // Only used for iOS
                if (!accepted) {
                  setShouldShowNotificationsPrompt(true);
                } else {
                  setShow(true);
                }
              }}
              registered={registered}
              t={t}
            />
            <MobileNotificationsInstructionsPrompt
              show={show}
              dismiss={() => setShow(false)}
              t={t}
            />
          </>
        )}
      </div>

      <div className="fixed bottom-24 flex w-full flex-row justify-center px-5">
        <GradientButton
          text={t("logout")}
          icon={<LogoutIcon />}
          buttonType="primary"
          onClick={logOut}
        />
      </div>
      {shouldShowNotificationsPrompt && (
        <NotificationsPrompt
          vapidKey={viewer?.vapidKey}
          dismiss={() => setShouldShowNotificationsPrompt(false)}
          registered={() => {
            setAccepted(true);
            setShouldShowNotificationsPrompt(false);
          }}
          t={t}
        />
      )}
    </>
  );
};
