import { Button } from "../button";
import { Headline, Paragraph } from "../typography";
import notif_showcase_dark from "../assets/png/notif_showcase-dark.png";
import { useNotificationContext } from "./NotificationProvider";

type NotificationsPromptProps = {
  dismiss: () => void;
  registered: (v: boolean) => void;
  vapidKey: string | undefined;
  t: (key: string) => string;
};

export const NotificationsPrompt = ({
  dismiss,
  registered,
  vapidKey,
  t,
}: NotificationsPromptProps) => {
  const { registerForWebPushNotifications } = useNotificationContext();

  return (
    <div className="z-50 fixed top-0 left-0 h-screen w-screen bg-primary-100 dark:bg-primary-900 justify-center items-center flex flex-col">
      <Headline
        size="xl"
        className="text-primary-900 dark:text-white justify-center w-52 text-center flex-wrap"
      >
        {t("notificationsPrompt.title")}
      </Headline>
      <img
        alt={notif_showcase_dark}
        src={notif_showcase_dark}
        className="max-w-xs self-center"
      />
      <div className="w-80 dark:text-greyscale-100 text-center">
        <Paragraph className="justify-center pb-10">
          {t("notificationsPrompt.description")}
        </Paragraph>
        <Paragraph>{t("notificationsPrompt.turnOffLater")}</Paragraph>
        <div className="flex flex-col justify-center items-center fixed bottom-0 left-0 w-screen">
          <Button
            disabled={false}
            text={t("notificationsPrompt.turnOnButton")}
            className="mb-1 w-72"
            onClick={() => {
              registerForWebPushNotifications(vapidKey, registered);
            }}
          />
          <Button
            disabled={false}
            variant="tertiary"
            text={t("notificationsPrompt.notNowButton")}
            className="mb-5"
            onClick={dismiss}
          />
        </div>
      </div>
    </div>
  );
};
