import { User } from "@frontend/lyng/assets/icons/16/outline";
import { Home } from "@frontend/lyng/assets/icons/16/filled";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

export const AppFooter: FunctionComponent = () => {
  return (
    <footer className="fixed bottom-0 left-0 z-20 flex h-20 w-full flex-row items-center justify-center px-5 text-3xl text-title bg-greyscale-800">
      <NavLink className="flex flex-1 items-center justify-center pb-10" to="">
        {({ isActive }) => (
          <>
            {isActive && (
              <div className="absolute h-0.5 w-1/2 top-0 bg-gradient-to-r from-transparent via-primary-500 to-transparent" />
            )}
            <Home className="text-primary-500 z-10 h-6 w-6 items-center" />
          </>
        )}
      </NavLink>
      <NavLink
        className="flex flex-1 items-center justify-center pb-10"
        to="/profile"
      >
        {({ isActive }) => (
          <>
            {isActive && (
              <div className="absolute h-0.5 w-1/2 top-0 bg-gradient-to-r from-transparent via-primary-500 to-transparent" />
            )}
            <User className="text-primary-500 z-10 h-6 w-6 items-center" />
          </>
        )}
      </NavLink>
    </footer>
  );
};
