import classNames from "classnames";
import { forwardRef } from "react";
import { LinkProps, Link as ReactRouterDomLink } from "react-router-dom";
import { match } from "ts-pattern";

type size = "m" | "s" | "xs" | "xxs";
export const Link = forwardRef<HTMLAnchorElement, LinkProps & { size?: size }>(
  function Link({ size = "m", ...props }, ref) {
    const sizeClass = match(size)
      .with("m", () => "text-lg")
      .with("s", () => "text-base")
      .with("xs", () => "text-sm")
      .with("xxs", () => "text-xs")
      .exhaustive();
    return (
      <ReactRouterDomLink
        className={classNames(
          "font-normal not-italic text-primary-700 underline hover:text-primary-300 hover:no-underline active:text-primary-900 disabled:text-greyscale-300",
          sizeClass
        )}
        {...props}
        ref={ref}
      />
    );
  }
);
