import { AnalyticsBrowser } from "@segment/analytics-next";
import { setTypewriterOptions } from "../typewriter/segment";

export const analytics = AnalyticsBrowser.load({
  writeKey: import.meta.env.VITE_SEGMENT_KEY,
});

setTypewriterOptions({
  analytics,
});
