import { ErrorResponse } from "@apollo/client/link/error";
import { useCallback } from "react";

import ApolloProvider from "./api/ApolloProvider";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

import AppRoot from "./AppRoot";

import initI18n from "./i18n";
import { TolgeeProvider } from "@tolgee/react";
import { Splash } from "./components/core/Splash";

const gatewayUri = import.meta.env.VITE_DOMAIN + "/graphql";
function App() {
  const getAccessToken: () => Promise<string | Error> =
    useCallback(async () => {
      return await fetchAuthSession().then((session) => {
        if (session?.tokens?.accessToken) {
          return session.tokens?.accessToken.toString();
        }
        return Promise.reject(Error("No session found"));
      });
    }, []);
  const tolgee = initI18n();

  return (
    <TolgeeProvider tolgee={tolgee} fallback={<Splash />}>
      <ApolloProvider
        uri={gatewayUri}
        onErrorResponse={(error: ErrorResponse | null) => {
          if (
            error &&
            error.networkError &&
            "statusCode" in error.networkError &&
            (error.networkError.statusCode === 401 ||
              error.networkError.statusCode === 403)
          ) {
            localStorage.removeItem("tenantId");
            signOut();
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onStartOperation={() => {}}
        tokenRequest={() => getAccessToken()}
      >
        {() => <AppRoot />}
      </ApolloProvider>
    </TolgeeProvider>
  );
}

export default App;
