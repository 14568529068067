import {
  createContext,
  useContext,
  ReactNode,
  memo,
  useState,
  useEffect,
  Dispatch,
} from "react";

export interface PWAPromptEvent extends Event {
  readonly userChoice?: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  readonly platforms?: string[];

  prompt(): Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
}

type Props = {
  children: ReactNode;
};

const PWAInstallContext = createContext<{
  event: PWAPromptEvent | null;
  setInstall: Dispatch<(() => void) | null> | null;
  install: (() => void) | null;
  setShowPrompt: Dispatch<(() => void) | null> | null;
  showPrompt: (() => void) | null;
  setIsAppInstalled: Dispatch<boolean> | null;
  isAppInstalled: boolean;
}>({
  event: null,
  setInstall: null,
  install: null,
  setShowPrompt: null,
  showPrompt: null,
  setIsAppInstalled: null,
  isAppInstalled: false,
});

// This provider is used to handle the PWA installation process
// it listens to the beforeinstallprompt event and stores the event
// beforeinstallprompt is a bit of a special case of an event
//
// First beforeinstallprompt is a global event and can only be listened to once
//
// It's also extremely finicky and it's not possible to listen to it in a component
// because of this it needs to be placed at the root of the application

const PWAInstallProvider = ({ children }: Props) => {
  const [event, setEvent] = useState<PWAPromptEvent | null>(null);
  const [install, setInstall] = useState<(() => void) | null>(null);
  const [showPrompt, setShowPrompt] = useState<(() => void) | null>(null);
  const [isAppInstalled, setIsAppInstalled] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      if ("prompt" in event) {
        setEvent(event as PWAPromptEvent);
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
  }, []);

  useEffect(() => {
    if (showPrompt && !isAppInstalled) {
      // Only trigger automatically once
      if (localStorage.getItem("pwaPrompt")) return;

      // setShouldShow(true);
      showPrompt();

      localStorage.setItem("pwaPrompt", "true");
    }
  }, [showPrompt, isAppInstalled]);

  return (
    <PWAInstallContext.Provider
      value={{
        event,
        setInstall,
        install,
        showPrompt,
        setShowPrompt,
        isAppInstalled,
        setIsAppInstalled,
      }}
    >
      {children}
    </PWAInstallContext.Provider>
  );
};

export function usePWAInstallContext() {
  return useContext(PWAInstallContext);
}

export default memo(PWAInstallProvider);
