import { P, match } from "ts-pattern";
import { SmallPillColors, SmallPillProps } from "./types";
import { Label } from "../typography";
import classNames from "classnames";

const renderMaybeIconOrDot = (
  Icon: SmallPillProps["icon"],
  side: "left" | "right",
  pillColor: SmallPillColors,
  iconOnly: boolean,
  className?: string,
) => {
  if (!Icon) {
    return null;
  }
  const iconColor = match(pillColor)
    .with("green", () => "text-greyscale-900")
    .with("red", () => "text-critical-800")
    .with("blue", () => "text-primary-600")
    .with("gray", () => "text-greyscale-600")
    .with("yellow", () => "text-greyscale-900")
    .with("darkBlue", () => "text-greyscale-0")
    .exhaustive();

  return (
    <span
      className={classNames(iconColor, {
        "mr-2": side === "left" && !iconOnly,
        "ml-2": side === "right" && !iconOnly,
      })}
    >
      {Icon}
    </span>
  );
};

export const SmallPill = ({
  text,
  color,
  icon,
  iconSide = "left",
  className,
}: SmallPillProps) => {
  const paddingClass = match([text, icon, iconSide])
    .with([P._, P.nullish, P._], () => "px-2")
    .with([P._, "dot", "left"], () => "pl-1 pr-2")
    .with([P._, "dot", "right"], () => "pl-2 pr-1")
    .with([P.nullish, P._, P._], ["", P._, P._], () => "px-1 py-1")
    .with([P._, P._, "left"], () => "pl-1 pr-2")
    .with([P._, P._, "right"], () => "pl-2 pr-1")
    .exhaustive();

  const textColor = match(color)
    .with("blue", () => "text-greyscale-900")
    .with("gray", () => "!text-greyscale-900")
    .with("green", () => "!text-greyscale-900")
    .with("red", () => "!text-greyscale-900")
    .with("yellow", () => "!text-greyscale-900")
    .with("darkBlue", () => "!text-greyscale-0")
    .exhaustive();

  const bgColor = match(color)
    .with("green", () => "bg-secondary-200")
    .with("red", () => "bg-critical-300")
    .with("blue", () => "bg-primary-100")
    .with("gray", () => "bg-greyscale-100")
    .with("yellow", () => "bg-accent-400")
    .with("darkBlue", () => "bg-primary-500")
    .exhaustive();

  return (
    <span
      className={classNames(
        className,
        "inline-flex flex-shrink-0 items-center rounded-full py-1",
        paddingClass,
        bgColor,
      )}
    >
      {iconSide === "left"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
      <Label size="xxs" className={textColor}>
        {text}
      </Label>
      {iconSide === "right"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
    </span>
  );
};
