import { Paragraph } from "../../typography";

type Props = {
  children: React.ReactNode;
};

export const ErrorMessage = ({ children }: Props) => {
  return (
    <Paragraph size="xs" type="critical" className="pl-4 text-sm">
      {children}
    </Paragraph>
  );
};
