import { useTranslate } from "@tolgee/react";
import { Headline, Paragraph } from "@frontend/lyng/typography";
import { GradientButton } from "./gradientButton/GradientButton";
import { useNavigate } from "react-router";
import Leaf from "../../assets/leafsNotFound.svg?react";

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  return (
    <div className="flex h-full flex-col ">
      <div
        className={
          "flex flex-col self-center text-center justify-center flex-1"
        }
      >
        <Headline className="font-poppins mb-6 text-white" size="xl">
          {t("notFound.title")}
        </Headline>
        <Paragraph size="m" className={"px-4 font-poppins text-white"}>
          {t("notFound.description")}
        </Paragraph>
      </div>

      <div className="text-poppins fixed bottom-0 w-full pb-8 px-4">
        <Leaf className=" float-right mb-8" />
        <GradientButton
          onClick={() => {
            navigate("/");
          }}
          text={t("notFound.goHome")}
          buttonType="primary"
        ></GradientButton>
      </div>
    </div>
  );
};
