import { DateTime, Duration } from "luxon";
import { Weekday } from "../api/generated/graphql";
import { Status } from "../types";
import { UseTranslateResult } from "@tolgee/react";
import { P, match } from "ts-pattern";

export function getStartOfWeek(): string {
  return DateTime.local().startOf("week").toISO() ?? "";
}
export function getEndOfWeek(): string {
  return DateTime.local().endOf("week").toISO() ?? "";
}

export function getDayFromEnum(
  weekday: Weekday,
  t: UseTranslateResult["t"],
): string {
  switch (weekday) {
    case Weekday.Monday:
      return t("date.monday", { count: 1 });
    case Weekday.Tuesday:
      return t("date.tuesday", { count: 1 });
    case Weekday.Wednesday:
      return t("date.wednesday", { count: 1 });
    case Weekday.Thursday:
      return t("date.thursday", { count: 1 });
    case Weekday.Friday:
      return t("date.friday", { count: 1 });
    case Weekday.Saturday:
      return t("date.saturday", { count: 1 });
    case Weekday.Sunday:
      return t("date.sunday", { count: 1 });
  }
}

export function getPluralDayFromEnum(
  weekday: Weekday,
  t: UseTranslateResult["t"],
): string {
  switch (weekday) {
    case Weekday.Monday:
      return t("date.monday", { count: 2 });
    case Weekday.Tuesday:
      return t("date.tuesday", { count: 2 });
    case Weekday.Wednesday:
      return t("date.wednesday", { count: 2 });
    case Weekday.Thursday:
      return t("date.thursday", { count: 2 });
    case Weekday.Friday:
      return t("date.friday", { count: 2 });
    case Weekday.Saturday:
      return t("date.saturday", { count: 2 });
    case Weekday.Sunday:
      return t("date.sunday", { count: 2 });
  }
}

export function frequency(
  weekdays: Weekday[],
  t: UseTranslateResult["t"],
  short?: boolean,
): string {
  const allWeekdays = [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
  ];

  // Empty weekdays default to daily
  if (weekdays.length === 0 || weekdays.length === 7) {
    return t("date.everyVisit");
  } else if (allWeekdays.every((d) => weekdays.includes(d))) {
    return t("date.everyWeekday");
  } else if (allWeekdays.every((d) => !weekdays.includes(d))) {
    return t("date.everyWeekend");
  } else if (weekdays.length === 1) {
    return getPluralDayFromEnum(weekdays[0], t);
  }

  if (short)
    return weekdays
      .map((weekday) => t(`date.${weekday.toLowerCase()}_short`))
      .join(", ");
  return weekdays.map((weekday) => getPluralDayFromEnum(weekday, t)).join(", ");
}

export const getRemainingTime = (
  from: DateTime,
  to: DateTime,
  visitStatus: Status,
  t: UseTranslateResult["t"],
) => {
  // Get the start time text in minutes if it's in the future
  // Get the end time text in minutes if it's currently happening

  const now = DateTime.local();

  const isFuture = from > now;
  if (isFuture && visitStatus !== "clockedIn") {
    const minutes = from.diff(now, "minutes").toObject().minutes;

    if (!minutes) {
      return "";
    }

    if (minutes <= 1) {
      return t("date.inLessThanOneMinute");
    }

    if (minutes > 60) {
      const days = Math.round(from.diff(now, "days").toObject().days ?? 0);
      if (days === 1) {
        return t("date.tomorrow");
      }

      const hours = Math.floor(minutes / 60);
      if (hours > 24) {
        return t("date.inXDays", { days: Math.round(days) });
      }

      return t("date.inXHoursXMin", {
        hours: hours,
        minutes: Math.round(minutes % 60),
      });
    }

    return t("date.inXMin", { minutes: Math.round(minutes) });
  }

  const isCurrent = now <= to;
  if (isCurrent) {
    const minutes = to.diff(now, "minutes").toObject().minutes ?? 0;
    if (minutes > 60) {
      return t("date.nowHrMinLeft", {
        hours: Math.floor(minutes / 60),
        minutes: Math.floor(minutes % 60),
      });
    }
    return t("date.nowMinLeft", { minutes: Math.round(minutes) });
  }
  return t("completed");
};

export function getDateValueWithUnit(
  value: number,
  t: UseTranslateResult["t"],
  unit: "d" | "h" | "m" | "s",
  abbreviate = true,
): string {
  switch (unit) {
    case "d":
      return `${value}${
        abbreviate ? t("date.day_short") : t("day", { value, count: value })
      }`;
    case "h":
      return `${value}${
        abbreviate ? t("date.hour_short") : t("hour", { value, count: value })
      }`;
    case "m":
      return `${value}${
        abbreviate
          ? t("date.minute_short")
          : t("minute", { value, count: value })
      }`;
    case "s":
      return `${value}${
        abbreviate
          ? t("date.second_short")
          : t("second", { value, count: value })
      }`;
  }
}

export function formatDuration(
  base: Duration | number,
  t: UseTranslateResult["t"],
  withSeconds = false,
  abbreviate = true,
  withHours = true,
): string {
  const duration = Duration.isDuration(base)
    ? base
    : Duration.fromObject({ minutes: base }).rescale();

  const diffDays = withHours ? Math.round(Math.abs(duration.days)) : 0;
  const diffHours = Math.round(
    Math.abs(duration.hours) + (withHours ? 0 : Math.abs(duration.days * 24)),
  );
  const diffMinutes = Math.round(Math.abs(duration.minutes));

  if (withSeconds) {
    const seconds = Math.abs(duration.seconds);
    const fixedSeconds = seconds.toFixed(0);
    const paddedSeconds = seconds < 10 ? `0${fixedSeconds}` : fixedSeconds;
    const paddedMinutes =
      Math.abs(duration.minutes) < 10 ? `0${diffMinutes}` : diffMinutes;
    return `${paddedMinutes}:${paddedSeconds}`;
  }
  if (diffDays > 0) {
    return `${getDateValueWithUnit(
      diffDays,
      t,
      "d",
      abbreviate,
    )} ${getDateValueWithUnit(
      diffHours,
      t,
      "h",
      abbreviate,
    )} ${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`;
  } else if (diffHours > 0) {
    return diffMinutes > 0
      ? `${getDateValueWithUnit(
          diffHours,
          t,
          "h",
          abbreviate,
        )} ${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`
      : `${getDateValueWithUnit(diffHours, t, "h", abbreviate)}`;
  } else {
    return `${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`;
  }
}

export const formatDurationLoosely = (
  base: Duration | number,
  t: UseTranslateResult["t"],
): string => {
  const duration = Duration.isDuration(base)
    ? base
    : Duration.fromObject({ minutes: base }).rescale();

  const diffDays = Math.round(Math.abs(duration.days));
  const diffHours = Math.round(Math.abs(duration.hours));
  const diffMinutes = Math.round(Math.abs(duration.minutes));

  if (diffDays > 0) {
    return `${getDateValueWithUnit(diffDays, t, "d")} ${getDateValueWithUnit(
      diffHours,
      t,
      "h",
    )}`;
  } else if (diffHours > 0) {
    return `${getDateValueWithUnit(diffHours, t, "h")} ${getDateValueWithUnit(
      diffMinutes,
      t,
      "m",
    )}`;
  } else {
    return `${getDateValueWithUnit(diffMinutes, t, "m")}`;
  }
};

export const formatTime = (
  date: string | DateTime,
  meridian = true,
  lowercase = true,
  autoAbbreviate = false,
) => {
  const dt = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);

  const abbreviateMinutes = autoAbbreviate ? dt.minute === 0 : false;

  const format = match({ meridian, abbreviateMinutes })
    .with({ meridian: true, abbreviateMinutes: false }, () => "hh:mm a")
    .with({ meridian: false, abbreviateMinutes: false }, () => "HH:mm")
    .with({ meridian: true, abbreviateMinutes: true }, () => "h a")
    .otherwise(() => "HH");
  const formatted = dt.toFormat(format);
  return lowercase ? formatted.toLowerCase() : formatted;
};

export const isToday = (date: DateTime) => {
  return date.toISODate() === DateTime.local().toISODate();
};

export const isTomorrow = (date: DateTime) => {
  return date.toISODate() === DateTime.local().plus({ days: 1 }).toISODate();
};

export const hasBirthday = (dateString: string): boolean => {
  const dt = DateTime.fromISO(dateString);
  const today = DateTime.now();
  return dt.day === today.day && dt.month === today.month;
};

export const calculateBirthday = (dateString: string): string => {
  const birthDate = DateTime.fromISO(dateString);
  const today = DateTime.now();
  const years = today.diff(birthDate, "years").toObject().years;

  return Math.floor(years ?? 0).toString();
};

export const calculateVisitDuration = (
  from: DateTime,
  to: DateTime,
  t: UseTranslateResult["t"],
) => {
  const daysTil = match({
    from,
    isToday: isToday(from),
    isTomorrow: isTomorrow(from),
  })
    .with({ isToday: true }, () => {
      return t("date.today");
    })
    .with({ isTomorrow: true }, () => {
      return t("date.tomorrow");
    })
    .with(
      {
        from: P.when(
          (from) =>
            (from.diff(DateTime.now(), "days").toObject().days ?? 0) < 0,
        ),
      },
      () => {
        return t("date.yesterday");
      },
    )
    .with(
      {
        from: P.when(
          (from) =>
            (from.diff(DateTime.now(), "days").toObject().days ?? 0) <= 7,
        ),
      },
      ({ from }) => {
        return t("date.inXDays", {
          days: Math.round(
            from.diff(DateTime.now(), "days").toObject().days ?? 0,
          ),
        });
      },
    )
    .with(
      {
        from: P.when(
          (from) =>
            (from.diff(DateTime.now(), "days").toObject().days ?? 0 > 7) &&
            (from.diff(DateTime.now(), "days").toObject().days ?? 0 <= 14),
        ),
      },
      () => {
        return t("date.nextWeek");
      },
    )
    .otherwise(({ from }) => {
      return t("date.inXDays", {
        days: Math.round(
          from.diff(DateTime.now(), "days").toObject().days ?? 0,
        ),
      });
    });

  return `${daysTil}, ${formatTime(from, true, true, true)} - ${formatTime(to, true, true, true)}`;
};
