import { DalaWordmark } from "../assets/svg/DalaWordmark";
import classNames from "classnames";
import { Outlet, useLocation } from "react-router-dom";

export const AuthenticatorLayout = () => {
  const { pathname } = useLocation();
  const mode = pathname === "/forgot-password-done" ? "light" : "dark";
  return (
    <div
      className={classNames(
        "flex min-h-full flex-1 flex-col justify-center px-4 py-12 transition-colors sm:px-6 lg:px-8",
        mode === "light" ? "bg-secondary-400" : "bg-primary-900"
      )}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="https://dala.care" className="mx-auto">
          <DalaWordmark
            className={classNames(
              "mx-auto",
              mode === "light" ? "text-greyscale-900" : "text-white"
            )}
          />
        </a>
      </div>

      <div className="mt-14 sm:mx-auto sm:w-full sm:max-w-[560px]">
        <Outlet />
      </div>
    </div>
  );
};
