type Props = {
  className?: string;
};
export const DalaWordmark = ({ className }: Props) => (
  <svg
    className={className}
    width="260"
    height="41"
    viewBox="0 0 260 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.000976562 25.0297C0.000976562 22.0391 0.605774 19.4222 1.81537 17.1792C3.02496 14.9006 4.67926 13.1383 6.77826 11.8922C8.91284 10.6461 11.2965 10.023 13.9291 10.023C16.206 10.023 18.3228 10.5571 20.2795 11.6252C22.2362 12.6577 23.7304 14.0284 24.7621 15.7373V0.25H29.6716V39.7694H24.7621V34.2687C23.8015 36.0132 22.3785 37.4552 20.4929 38.5945C18.6074 39.6982 16.4017 40.25 13.8757 40.25C11.2787 40.25 8.91284 39.6091 6.77826 38.3274C4.67926 37.0457 3.02496 35.2478 1.81537 32.9336C0.605774 30.6194 0.000976562 27.9848 0.000976562 25.0297ZM24.7621 25.0831C24.7621 22.8757 24.3174 20.9532 23.428 19.3154C22.5386 17.6777 21.329 16.4316 19.7992 15.5771C18.305 14.687 16.6507 14.242 14.8363 14.242C13.0219 14.242 11.3676 14.6692 9.8734 15.5237C8.3792 16.3782 7.18739 17.6243 6.29798 19.262C5.40858 20.8998 4.96387 22.8223 4.96387 25.0297C4.96387 27.2727 5.40858 29.2309 6.29798 30.9042C7.18739 32.5419 8.3792 33.8059 9.8734 34.6959C11.3676 35.5504 13.0219 35.9776 14.8363 35.9776C16.6507 35.9776 18.305 35.5504 19.7992 34.6959C21.329 33.8059 22.5386 32.5419 23.428 30.9042C24.3174 29.2309 24.7621 27.2905 24.7621 25.0831Z"
      fill="currentColor"
    />
    <path
      d="M36.0637 25.0297C36.0637 22.0391 36.6685 19.4222 37.8781 17.1792C39.0877 14.9006 40.742 13.1383 42.841 11.8922C44.9755 10.6461 47.3414 10.023 49.9384 10.023C52.4999 10.023 54.7235 10.5749 56.609 11.6786C58.4945 12.7823 59.8998 14.1708 60.8248 15.8441V10.5037H65.7343V39.7694H60.8248V34.3221C59.8642 36.031 58.4234 37.4552 56.5023 38.5945C54.6167 39.6982 52.411 40.25 49.8851 40.25C47.288 40.25 44.94 39.6091 42.841 38.3274C40.742 37.0457 39.0877 35.2478 37.8781 32.9336C36.6685 30.6194 36.0637 27.9848 36.0637 25.0297ZM60.8248 25.0831C60.8248 22.8757 60.3801 20.9532 59.4907 19.3154C58.6013 17.6777 57.3917 16.4316 55.8619 15.5771C54.3677 14.687 52.7134 14.242 50.899 14.242C49.0846 14.242 47.4303 14.6692 45.9361 15.5237C44.4419 16.3782 43.2501 17.6243 42.3607 19.262C41.4713 20.8998 41.0266 22.8223 41.0266 25.0297C41.0266 27.2727 41.4713 29.2309 42.3607 30.9042C43.2501 32.5419 44.4419 33.8059 45.9361 34.6959C47.4303 35.5504 49.0846 35.9776 50.899 35.9776C52.7134 35.9776 54.3677 35.5504 55.8619 34.6959C57.3917 33.8059 58.6013 32.5419 59.4907 30.9042C60.3801 29.2309 60.8248 27.2905 60.8248 25.0831Z"
      fill="currentColor"
    />
    <path d="M78.7969 0.25V39.7694H73.9408V0.25H78.7969Z" fill="currentColor" />
    <path
      d="M85.259 25.0297C85.259 22.0391 85.8638 19.4222 87.0734 17.1792C88.283 14.9006 89.9373 13.1383 92.0363 11.8922C94.1709 10.6461 96.5367 10.023 99.1338 10.023C101.695 10.023 103.919 10.5749 105.804 11.6786C107.69 12.7823 109.095 14.1708 110.02 15.8441V10.5037H114.93V39.7694H110.02V34.3221C109.06 36.031 107.619 37.4552 105.698 38.5945C103.812 39.6982 101.606 40.25 99.0804 40.25C96.4834 40.25 94.1353 39.6091 92.0363 38.3274C89.9373 37.0457 88.283 35.2478 87.0734 32.9336C85.8638 30.6194 85.259 27.9848 85.259 25.0297ZM110.02 25.0831C110.02 22.8757 109.575 20.9532 108.686 19.3154C107.797 17.6777 106.587 16.4316 105.057 15.5771C103.563 14.687 101.909 14.242 100.094 14.242C98.28 14.242 96.6257 14.6692 95.1315 15.5237C93.6373 16.3782 92.4454 17.6243 91.556 19.262C90.6666 20.8998 90.2219 22.8223 90.2219 25.0297C90.2219 27.2727 90.6666 29.2309 91.556 30.9042C92.4454 32.5419 93.6373 33.8059 95.1315 34.6959C96.6257 35.5504 98.28 35.9776 100.094 35.9776C101.909 35.9776 103.563 35.5504 105.057 34.6959C106.587 33.8059 107.797 32.5419 108.686 30.9042C109.575 29.2309 110.02 27.2905 110.02 25.0831Z"
      fill="currentColor"
    />
    <path
      d="M143.119 25.0831C143.119 22.0569 143.724 19.4222 144.934 17.1792C146.143 14.9006 147.816 13.1383 149.95 11.8922C152.12 10.6461 154.593 10.023 157.368 10.023C160.961 10.023 163.914 10.8953 166.226 12.6399C168.574 14.3844 170.122 16.8054 170.869 19.9029H165.639C165.141 18.1227 164.163 16.7164 162.704 15.6839C161.281 14.6514 159.502 14.1352 157.368 14.1352C154.593 14.1352 152.352 15.0965 150.644 17.019C148.936 18.906 148.082 21.594 148.082 25.0831C148.082 28.6078 148.936 31.3314 150.644 33.254C152.352 35.1766 154.593 36.1378 157.368 36.1378C159.502 36.1378 161.281 35.6394 162.704 34.6425C164.127 33.6456 165.106 32.2215 165.639 30.3702H170.869C170.086 33.3608 168.521 35.764 166.173 37.5798C163.825 39.3599 160.89 40.25 157.368 40.25C154.593 40.25 152.12 39.6269 149.95 38.3808C147.816 37.1347 146.143 35.3724 144.934 33.0938C143.724 30.8152 143.119 28.145 143.119 25.0831Z"
      fill="currentColor"
    />
    <path
      d="M175.534 25.0297C175.534 22.0391 176.139 19.4222 177.349 17.1792C178.558 14.9006 180.212 13.1383 182.311 11.8922C184.446 10.6461 186.812 10.023 189.409 10.023C191.97 10.023 194.194 10.5749 196.08 11.6786C197.965 12.7823 199.37 14.1708 200.295 15.8441V10.5037H205.205V39.7694H200.295V34.3221C199.335 36.031 197.894 37.4552 195.973 38.5945C194.087 39.6982 191.882 40.25 189.356 40.25C186.759 40.25 184.41 39.6091 182.311 38.3274C180.212 37.0457 178.558 35.2478 177.349 32.9336C176.139 30.6194 175.534 27.9848 175.534 25.0297ZM200.295 25.0831C200.295 22.8757 199.851 20.9532 198.961 19.3154C198.072 17.6777 196.862 16.4316 195.332 15.5771C193.838 14.687 192.184 14.242 190.37 14.242C188.555 14.242 186.901 14.6692 185.407 15.5237C183.912 16.3782 182.721 17.6243 181.831 19.262C180.942 20.8998 180.497 22.8223 180.497 25.0297C180.497 27.2727 180.942 29.2309 181.831 30.9042C182.721 32.5419 183.912 33.8059 185.407 34.6959C186.901 35.5504 188.555 35.9776 190.37 35.9776C192.184 35.9776 193.838 35.5504 195.332 34.6959C196.862 33.8059 198.072 32.5419 198.961 30.9042C199.851 29.2309 200.295 27.2905 200.295 25.0831Z"
      fill="currentColor"
    />
    <path
      d="M218.267 15.2567C219.121 13.5833 220.331 12.2838 221.896 11.3581C223.497 10.4325 225.436 9.96962 227.713 9.96962V14.9896H226.432C220.989 14.9896 218.267 17.9447 218.267 23.8548V39.7694H213.411V10.5037H218.267V15.2567Z"
      fill="currentColor"
    />
    <path
      d="M260.001 24.015C260.001 24.9407 259.948 25.9198 259.841 26.9523H236.467C236.645 29.8361 237.623 32.0969 239.402 33.7346C241.217 35.3368 243.405 36.1378 245.966 36.1378C248.065 36.1378 249.808 35.6572 251.196 34.6959C252.619 33.699 253.615 32.3817 254.184 30.744H259.414C258.631 33.5566 257.066 35.853 254.718 37.6332C252.37 39.3777 249.453 40.25 245.966 40.25C243.191 40.25 240.701 39.6269 238.495 38.3808C236.325 37.1347 234.617 35.3724 233.372 33.0938C232.127 30.7796 231.504 28.1094 231.504 25.0831C231.504 22.0569 232.109 19.4044 233.319 17.1258C234.528 14.8472 236.218 13.1027 238.388 11.8922C240.594 10.6461 243.12 10.023 245.966 10.023C248.741 10.023 251.196 10.6283 253.33 11.8388C255.465 13.0493 257.102 14.7226 258.24 16.8588C259.414 18.9594 260.001 21.3448 260.001 24.015ZM254.985 23.0003C254.985 21.149 254.576 19.5646 253.757 18.2473C252.939 16.8944 251.818 15.8797 250.395 15.2033C249.008 14.4912 247.46 14.1352 245.753 14.1352C243.298 14.1352 241.199 14.9184 239.456 16.485C237.748 18.0515 236.77 20.2233 236.521 23.0003H254.985Z"
      fill="currentColor"
    />
    <path
      d="M133.052 26.2963C133.052 27.9657 131.7 29.3189 130.032 29.3189C128.364 29.3189 127.011 27.9657 127.011 26.2963C127.011 24.627 128.364 23.2737 130.032 23.2737C131.7 23.2737 133.052 24.627 133.052 26.2963Z"
      fill="currentColor"
    />
  </svg>
);
