import { useEffect, useState, useMemo } from "react";
import { DateTime, Duration } from "luxon";
import { useTranslate } from "@tolgee/react";

import { formatDurationLoosely } from "../../../utils/dateUtils";

export const CardStatus = {
  pending: "pending",
  soon: "soon",
  now: "now",
  late: "late",
  away: "away",
  started: "started",
  over: "over",
  done: "done",
  cancelled: "cancelled",
  error: "error",
} as const;

export type CardStatus = (typeof CardStatus)[keyof typeof CardStatus];

export const useRemainingTime = (target: DateTime): Duration => {
  const [remainingTime, setRemainingTime] = useState<Duration>(
    target.diffNow(["days", "hours", "minutes"]),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(target.diffNow(["days", "hours", "minutes"]));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [target]);
  return remainingTime;
};

interface IGetCardStatus {
  from: DateTime;
  to: DateTime;
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  cancelled?: boolean;
}

const getCardStatus = ({
  from,
  to,
  clockIn,
  clockOut,
  cancelled,
}: IGetCardStatus): CardStatus => {
  if (cancelled) return CardStatus.cancelled;

  if (!clockIn && !clockOut) {
    const startDiff = from.diffNow(["minute"]);
    const minToStart = startDiff.toObject().minutes ?? 0;

    if (minToStart < -15) return CardStatus.away;
    if (minToStart >= -15 && minToStart < -1) return CardStatus.late;
    if (minToStart >= -1 && minToStart < 0) return CardStatus.now;
    if (minToStart <= 15) return CardStatus.soon;
    return CardStatus.pending;
  }

  if (clockIn && !clockOut) {
    if (to.diffNow().toMillis() < 0) return CardStatus.over;
    return CardStatus.started;
  }

  if (clockIn && clockOut) return CardStatus.done;

  return CardStatus.error;
};

type IGetDetails = {
  from: DateTime;
  to: DateTime;
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  readOnly?: boolean;
  cancelled?: boolean;
};

type Ret = [CardStatus, string, string?];

export const useGetDetails = ({
  from,
  to,
  readOnly,
  clockIn,
  clockOut,
  cancelled,
}: IGetDetails): [CardStatus, string, string?] => {
  const { t } = useTranslate();
  const [data, setData] = useState<Ret>([CardStatus.pending, ""]);

  const target = useMemo(() => (clockIn ? to : from), [clockIn, to, from]);
  const remaining = useRemainingTime(target);
  const doneTime =
    clockIn && clockOut
      ? clockOut?.diff(clockIn, ["day", "hour", "minute"])
      : null;

  const time = formatDurationLoosely(doneTime || remaining, t);

  useEffect(() => {
    const cardStatus = getCardStatus({
      from,
      to,
      clockIn,
      clockOut,
      cancelled,
    });

    const getData = (): [string, string?] => {
      if (cardStatus === CardStatus.cancelled) {
        if (readOnly) return ["", t("visitCard.cancelled") ?? ""];
        return [t("visitCard.visitCancelled"), t("visitCard.cancelled") ?? ""];
      }

      if (cardStatus === CardStatus.pending) {
        if (readOnly) return [t("visitCard.startsIn", { time })];
        return [t("visitCard.youStartIn", { time })];
      }

      if (cardStatus === CardStatus.soon) {
        if (readOnly) return [t("visitCard.startsIn", { time })];
        return [
          t("visitCard.youStartingSoon"),
          t("visitCard.in", { time }) ?? "",
        ];
      }

      if (cardStatus === CardStatus.now) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [t("visitCard.youStartingNow"), t("visitCard.now") ?? ""];
      }

      if (cardStatus === CardStatus.late) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [
          t("visitCard.youStartingNow"),
          t("visitCard.ago", { time }) ?? "",
        ];
      }

      if (cardStatus === CardStatus.away) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [t("visitCard.notStarted"), t("visitCard.ago", { time }) ?? ""];
      }

      if (cardStatus === CardStatus.started) {
        if (readOnly)
          return [t("visitCard.isThere"), t("visitCard.left", { time }) ?? ""];
        return [t("visitCard.youAreHere"), t("visitCard.left", { time }) ?? ""];
      }

      if (cardStatus === CardStatus.over) {
        if (readOnly)
          return [t("visitCard.isThere"), t("visitCard.over", { time }) ?? ""];
        return [t("visitCard.youAreHere"), t("visitCard.over", { time }) ?? ""];
      }

      if (readOnly) return [t("visitCard.completed"), time];
      return [t("visitCard.completed"), time];
    };

    setData([cardStatus, ...getData()]);
  }, [from, to, clockIn, clockOut, readOnly, cancelled, time, t]);

  return data;
};
