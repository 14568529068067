import {
  createContext,
  useReducer,
  Dispatch,
  useContext,
  ReactNode,
  memo,
} from "react";

import { CareteamActions, CareteamState } from "./Types";
import CareReducer, { getInitialState } from "./CareReducer";

type Props = {
  children: ReactNode;
};

export const initialContextState: CareteamState = getInitialState();

const CareContext = createContext<{
  state: CareteamState;
  dispatch: Dispatch<CareteamActions>;
}>({
  state: initialContextState,
  dispatch: () => null,
});

const CareProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(CareReducer, { ...initialContextState });

  return (
    <CareContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CareContext.Provider>
  );
};

export function useCareContext() {
  return useContext(CareContext);
}

export default memo(CareProvider);
