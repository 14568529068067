import { useEffect } from "react";

export const useGeoLocation = () => {
  useEffect(() => {
    if (!navigator.geolocation) {
      return;
    }
    const watchId = navigator.geolocation.watchPosition(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      (err) => {
        console.error("watchPosition() error", err);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 1000 * 10, // 10 seconds
        timeout: 1000 * 10, // 10 seconds
      }
    );
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const getPosition = (): Promise<GeolocationPosition | null> => {
    if (!navigator.geolocation) {
      return Promise.resolve(null);
    }

    const timeoutPromise = new Promise<null>((resolve) => {
      setTimeout(() => {
        console.error("getCurrentPosition() timeout");
        resolve(null);
      }, 1000 * 5); // 5 seconds
    });

    const getCurrentPositionPromise = new Promise<GeolocationPosition | null>(
      (resolve) => {
        navigator.geolocation.getCurrentPosition(
          resolve,
          () => {
            console.error("getCurrentPosition() error");
            resolve(null);
          },
          {
            enableHighAccuracy: true,
            maximumAge: Infinity, // Use cached result from watchPosition() if available
            timeout: 1000 * 5, // 5 seconds
          }
        );
      }
    );
    return Promise.race([timeoutPromise, getCurrentPositionPromise]);
  };

  return { getPosition };
};
