import { Button } from "../button";
import isiOS from "is-ios";
import { useNotificationContext } from "./NotificationProvider";
import { Headline, Paragraph } from "../typography";
import leaves from "../assets/png/leafs-notif-card.png";

type MobileNotificationsCardProps = {
  vapidKey: string | undefined;
  registered: (v: boolean) => void;
  showInstructions: () => void;
  t: (key: string) => string;
};

export const MobileNotificationsCard = ({
  vapidKey,
  registered,
  showInstructions,
  t,
}: MobileNotificationsCardProps) => {
  const { registerForWebPushNotifications } = useNotificationContext();

  return (
    <ul className="flex flex-col gap-2 p-4 bg-greyscale-800 rounded-2xl relative">
      <img
        alt={leaves}
        src={leaves}
        className="w-16 h-16 absolute top-3 right-0"
      />
      <Headline size="m" className="dark:text-gray-100 my-3">
        {t("mobileNotificationsCard.title")}
      </Headline>
      <Paragraph className="dark:text-gray-100 -mb-2">
        {isiOS
          ? t("mobileNotificationsCard.iOSInstructions")
          : t("mobileNotificationsCard.enableNotifications")}
      </Paragraph>
      <Paragraph className="dark:text-gray-100 mb-3">
        {t("mobileNotificationsCard.turnOffLater")}
      </Paragraph>
      <Button
        text={
          isiOS
            ? t("mobileNotificationsCard.showMeHowButton")
            : t("mobileNotificationsCard.enableNotificationsButton")
        }
        variant="secondary"
        size="md"
        onClick={() => {
          isiOS
            ? showInstructions()
            : registerForWebPushNotifications(vapidKey, registered);
        }}
        disabled={false}
      />
    </ul>
  );
};
