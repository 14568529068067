import { useTranslate } from "@tolgee/react";
import { Button } from "../button";
type Props = {
  onClick: () => void;
};

export const NotificationsMarkAsReadButton = ({ onClick }: Props) => {
  const { t } = useTranslate();
  return (
    <div className="flex items-center justify-center bg-primary-0 dark:bg-greyscale-800 w-full h-full p-2">
      <Button
        variant="tertiary"
        disabled={false}
        onClick={onClick}
        text={t("notifications.markAllAsRead") ?? "Mark as read"}
      ></Button>
    </div>
  );
};
