import { Button } from "../button";
import Modal from "../modal/Modal";
import { Paragraph } from "../typography";

type MobileNotificationsInstructionsPromptProps = {
  dismiss: () => void;
  show: boolean;
  t: (key: string) => string;
};

const Point = ({ children }: { children: React.ReactNode }) => (
  <li className="flex pt-1">
    <div className="h-full flex items-center justify-center pr-5 dark:text-primary-300">
      •
    </div>
    {children}
  </li>
);

type InstructionsProps = {
  t: (key: string) => string;
};

const Instructions = ({ t }: InstructionsProps) => (
  <div className="dark:text-greyscale-100 dark:bg-greyscale-800 rounded-lg border-greyscale-700 border p-4 font-inclusive text-lg mt-10 mb-10">
    <ul>
      <Point>
        <Paragraph>
          {t(
            "mobileNotificationsInstructionsPrompt.instructions.openSettingsApp",
          )}
        </Paragraph>
      </Point>
      <Point>
        <Paragraph>
          {t(
            "mobileNotificationsInstructionsPrompt.instructions.tapAppsAndNotifications",
          )}
        </Paragraph>
      </Point>
      <Point>
        <Paragraph>
          {t(
            "mobileNotificationsInstructionsPrompt.instructions.tapSeeAllApps",
          )}
        </Paragraph>
      </Point>
      <Point>
        <Paragraph>
          {t(
            "mobileNotificationsInstructionsPrompt.instructions.tapNotifications",
          )}
        </Paragraph>
      </Point>
      <Point>
        <Paragraph>
          {t(
            "mobileNotificationsInstructionsPrompt.instructions.toggleShowNotifications",
          )}
        </Paragraph>
      </Point>
    </ul>
  </div>
);

export const MobileNotificationsInstructionsPrompt = ({
  dismiss,
  show,
  t,
}: MobileNotificationsInstructionsPromptProps) => {
  return (
    <Modal show={show}>
      <Modal.Title>
        {t("mobileNotificationsInstructionsPrompt.title")}
      </Modal.Title>
      <Instructions t={t} />
      <Modal.Footer>
        <Button
          disabled={false}
          text={t("mobileNotificationsInstructionsPrompt.dismiss")}
          className="mb-1"
          onClick={() => {
            dismiss();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
