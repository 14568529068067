import { FunctionComponent } from "react";
import { useTranslate } from "@tolgee/react";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "@frontend/lyng/assets/icons/24/outline/chevron-left.svg?react";
import { Button } from "@frontend/lyng/button";

type Props = {
  title: string;
  showTitle: boolean;
  canBack: boolean;
};

export const AppHeader: FunctionComponent<Props> = ({
  title,
  showTitle,
  canBack,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isHistoryAvailable = location.key !== "default";
  const { t } = useTranslate();
  return (
    <div className="z-10 flex w-full flex-row">
      {canBack && (
        <div className="relative pt-10 pl-4">
          <Button
            variant="secondary"
            onClick={() => {
              if (isHistoryAvailable) {
                navigate(-1);
                return;
              }
              navigate("/");
            }}
            icon={BackIcon}
            iconPosition="only"
          />
        </div>
      )}
      {showTitle && (
        <div className="grow px-4 pb-5 pt-12 text-2xl font-bold text-white">
          {t("pages." + title)}
        </div>
      )}
    </div>
  );
};
