type Props = {
  firstName?: string;
  lastName?: string;
  img?: string;
};

export const Avatar = ({ firstName = "", lastName = "", img }: Props) => {
  const text =
    firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase() ||
    "-";
  return (
    <div className="flex h-7 w-7 shrink-0 items-center justify-center overflow-hidden rounded-full bg-primary-90 p-2">
      {img ? (
        <img src={img} alt={text} />
      ) : (
        <p className="text-xs font-medium">{text}</p>
      )}
    </div>
  );
};
