import React from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { useTranslate } from "@tolgee/react";

import { isToday, isTomorrow } from "../../utils/dateUtils";
import { GroupedVisits } from "./Schedule";
import { VisitCard, VisitDay } from "../../components/core";

type Props = {
  groupedVisits: GroupedVisits;
};

const VisitGroups = ({ groupedVisits }: Props) => {
  const { t } = useTranslate();
  const header = (date: DateTime) => {
    if (isToday(date) || isTomorrow(date)) {
      return (
        <div
          key={isToday(date) ? "today-header" : "tomorrow-header"}
          className="pb-2 pl-3 pt-2 text-xl text-title"
        >
          {isToday(date) ? t("date.today") : t("date.tomorrow")}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="z-1 flex grow flex-col">
      {Object.keys(groupedVisits)
        .sort()
        .map((day) => {
          const date = DateTime.fromMillis(Number(day));
          const visits = groupedVisits[Number(date)].sort((a, b) => {
            return a.from.toMillis() - b.from.toMillis();
          });
          return (
            <React.Fragment key={day}>
              {header(date)}
              <div className="mb-2 flex flex-row">
                <VisitDay date={date} shouldSticky={visits.length > 1} />
                <div className="relative mx-2 flex grow flex-col gap-2 bg-main">
                  {visits.map((visit) => {
                    return (
                      <React.Fragment key={visit.id}>
                        <Link to={`/visit/${visit.id}`}>
                          <VisitCard {...visit} />
                        </Link>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default VisitGroups;
