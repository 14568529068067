import classNames from "classnames";
import { match } from "ts-pattern";

type Props = {
  children: React.ReactNode;
  size: "xxl" | "xl" | "l" | "m";
  weight?: "bold" | "normal";
  className?: string;
};
const Headline = ({ children, size, className }: Props) => {
  const sizeClass = match(size)
    .with("xxl", () => "text-4xl")
    .with("xl", () => "text-3xl")
    .with("l", () => "text-2xl")
    .with("m", () => "text-xl")
    .exhaustive();

  return (
    <h1
      className={classNames(
        sizeClass,
        "text-greyscale-900 dark:text-greyscale-100 font-semibold",
        className,
      )}
    >
      {children}
    </h1>
  );
};

type HeadlineContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const HeadlineContainer = ({ className, children }: HeadlineContainerProps) => {
  return (
    <div
      className={classNames(
        className,
        "-mx-4 flex flex-col justify-between gap-3 pb-6 pt-8 sm:flex-row sm:pl-7 sm:pr-2 md:mx-0",
      )}
    >
      {children}
    </div>
  );
};

export { Headline, HeadlineContainer };
