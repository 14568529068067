import { useNavigate, useParams } from "react-router-dom";
import {
  Checkboxes,
  DatePicker,
  FormGroupStacked,
  Select,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { Tabs } from "@frontend/lyng/tabs";
import { useMemo, useState } from "react";
import { Headline, Label } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";
import { useCareContext } from "../../providers";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DateTime } from "luxon";
import { z } from "zod";
import {
  CareRecipient,
  NameOrder,
  useActivitiesByCareRecipientIdQuery,
  useCareRecipientsQuery,
  useCaregiverAvailabilityQuery,
  useClockInMutation,
  useVisitByIdQuery,
  useVisitCreateMutation,
  useVisitLogCreateMutation,
  useVisitUpdateMutation,
} from "../../api/generated/graphql";
import { Button } from "@frontend/lyng/button";
import { useSortingOptions } from "@frontend/lyng/utils/hooks/useSortingOptions";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../utils/toastUtils";
import { VisitConflictModal } from "./VisitConflictModal";
import { VisitDuration } from "../../components/core/visitDuration/VisitDuration";

const schema = z
  .object({
    date: z.custom<DateTime>((value) => {
      return (
        DateTime.isDateTime(value) &&
        value.diff(DateTime.now()).as("days") >= -1
      );
    }, "visitWizard.error.date"),
    startNow: z.boolean(),
    startTime: z.object(timeSchemaShape).nullable(),
    duration: z.number({ required_error: "visitWizard.error.duration" }),
    careRecipientId: z.string().optional(),
    activities: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.startNow && !data.startTime) {
      ctx.addIssue({
        message: "visitWizard.error.startTime",
        path: ["startTime"],
        code: z.ZodIssueCode.invalid_date,
      });
    }

    if (data.duration < 1) {
      ctx.addIssue({
        message: "visitWizard.error.duration",
        path: ["duration"],
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        inclusive: true,
        type: "number",
      });
    }

    if (data.careRecipientId === undefined) {
      ctx.addIssue({
        message: "visitWizard.error.careRecipientId",
        path: ["careRecipientId"],
        code: z.ZodIssueCode.invalid_type,
        expected: "string",
        received: "undefined",
      });
    }
  });

type FormValues = z.infer<typeof schema>;

const getName = (
  name: Pick<CareRecipient, "firstName" | "lastName">,
  nameOrder: NameOrder,
): string | null => {
  if (!name.firstName || !name.lastName) return null;
  return nameOrder === "FIRST_LAST"
    ? name.firstName.toLowerCase()
    : name.lastName.toLocaleLowerCase();
};

const getFullName = (
  name: Pick<CareRecipient, "firstName" | "lastName">,
  nameOrder: NameOrder,
): string => {
  if (!name.firstName || !name.lastName) return "";
  return nameOrder === "FIRST_LAST"
    ? `${name.firstName} ${name.lastName}`
    : `${name.lastName} ${name.firstName}`;
};

export const VisitWizard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { viewer },
  } = useCareContext();
  const { collator, nameOrder } = useSortingOptions(viewer?.tenantSettings);
  const { t } = useTranslate();
  const [conflict, setConflict] = useState(false);
  const [saveVisitDefFn, setSaveVisitDefFn] = useState<() => void>(
    () => Function,
  );

  const isNew = !id;

  const visit = useVisitByIdQuery({
    skip: !id,
    fetchPolicy: "cache-and-network",
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  const visitStartTime = visit.data?.visitById?.start
    ? DateTime.fromISO(visit.data.visitById.start)
    : DateTime.now();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(
      () => ({
        activities: [],
        startNow: isNew,
        startTime: isNew ? null : visitStartTime.toObject(),
        duration: isNew ? 15 : visit.data?.visitById?.durationMinutes,
        date: visitStartTime,
        careRecipientId: visit.data?.visitById?.careRecipient.id,
      }),
      [visit.data, isNew, visitStartTime],
    ),
  });

  const selectedCareRecipient = watch("careRecipientId");
  const selectedStartTime = watch("startTime");

  const tab = watch("startNow") ? "now" : "schedule";
  const duration = watch("duration");

  const availability = useCaregiverAvailabilityQuery({
    skip: !viewer && !selectedStartTime,
    fetchPolicy: "cache-and-network",
    variables: {
      caregiverId: viewer?.id ?? "",
      input: {
        start: selectedStartTime
          ? DateTime.fromObject(selectedStartTime).toISO() ?? ""
          : "",
        durationMinutes: duration,
      },
    },
  });

  const careRecipients = useCareRecipientsQuery({
    skip: !viewer,
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        officeIds:
          viewer?.tenantAccess.offices.map((office) => office.id) ?? [],
      },
    },
  });

  const careRecipientOptions = useMemo(() => {
    if (
      !careRecipients.data ||
      !careRecipients.data.careRecipients ||
      !nameOrder
    ) {
      return [];
    }

    return [...careRecipients.data.careRecipients]
      .filter((cr) => cr.deactivatedAt === null)
      .sort((a, b) => {
        const nameA = getName(a, nameOrder);
        const nameB = getName(b, nameOrder);
        if (nameA !== null && nameB !== null) {
          return collator.compare(nameA, nameB);
        } else return 0;
      })
      .map((cr) => {
        return {
          value: cr.id,
          label: getFullName(cr, nameOrder),
        };
      });
  }, [careRecipients.data, collator, nameOrder]);

  const [newVisitMutation] = useVisitCreateMutation();
  const [newVisitLogMutation] = useVisitLogCreateMutation();
  const [updateVisitMutation] = useVisitUpdateMutation();
  const [clockInMutation] = useClockInMutation();

  const allActivities = useActivitiesByCareRecipientIdQuery({
    skip: !selectedCareRecipient || !isNew,
    fetchPolicy: "cache-and-network",
    variables: {
      careRecipientId: selectedCareRecipient ?? "",
    },
  });

  const activities =
    allActivities.data?.activitiesByCareRecipientId.map((activity) => ({
      ...activity,
      selected:
        visit.data?.visitById?.activities.some((a) => a.id === activity.id) ??
        false,
    })) ?? [];

  const saveVisit = (data: FormValues) => {
    if (!viewer) {
      console.error("No viewer");
      return;
    }

    if (!isNew && data.startTime) {
      // Visit definition requires an explicit start time
      const promise = updateVisitMutation({
        variables: {
          input: {
            visitInstanceId: id,
            start: data.date
              .set({
                hour: data.startTime.hour,
                minute: data.startTime.minute,
              })
              .toISO(),
            durationMinutes: data.duration,
            visitorIds: visit.data?.visitById?.visitorIds ?? [],
          },
        },
      }).then((visit) => {
        if (data.startNow && visit.data?.visitUpdate) {
          return clockInMutation({
            variables: {
              input: {
                visitInstanceId: visit.data?.visitUpdate.id,
              },
            },
          }).then(() => {
            navigate("/visit/" + visit.data?.visitUpdate.id, { replace: true });
          });
        } else {
          navigate("/", { replace: true });
        }
      });

      toast.promise(promise, {
        loading: t("visitWizard.updating"),
        success: () => t("visitWizard.updated"),
        error: (err) => errorToToastMessage(err),
      });
    } else if (isNew && data.careRecipientId) {
      // If it's now it has to be a visit log
      if (tab === "now") {
        const office = careRecipients.data?.careRecipients.find(
          (cr) => cr.id === selectedCareRecipient,
        )?.office;

        if (!office) {
          console.error("Care recipient has no office");
          return;
        }

        const promise = newVisitLogMutation({
          variables: {
            input: {
              careRecipientId: data.careRecipientId,
              startDate: DateTime.now().toISO(),
              durationMinutes: data.duration,
              visitorIds: [viewer.id],
              officeId: office.id,
              clockInTime: DateTime.now().toISO(),
              activityIds: data.activities,
            },
          },
        }).then((val) => {
          navigate("/visit/" + val.data?.visitLogCreate?.id, { replace: true });
        });

        toast.promise(promise, {
          loading: t("visitWizard.creating"),
          success: () => t("visitWizard.created"),
          error: (err) => errorToToastMessage(err),
        });
      } else if (data.startTime) {
        const saveNewVisit = () => {
          if (data.careRecipientId === undefined) {
            console.error("No care recipient");
            return;
          }
          // Visit definition requires an explicit start time
          const promise = newVisitMutation({
            variables: {
              input: {
                careRecipientId: data.careRecipientId,
                start:
                  data.date
                    .set({
                      hour: data.startTime?.hour ?? 0,
                      minute: data.startTime?.minute ?? 0,
                    })
                    .toISO() ?? "",
                durationMinutes: data.duration,
                visitorIds: [viewer.id],
                activityIds: data.activities,
              },
            },
          }).then(() => {
            navigate("/", { replace: true });
          });

          toast.promise(promise, {
            loading: t("visitWizard.creating"),
            success: () => t("visitWizard.created"),
            error: (err) => errorToToastMessage(err),
          });
        };

        const availabilityInfo = availability.data?.caregiverAvailability;

        if (availabilityInfo && !availabilityInfo.firstVisit) {
          setConflict(true);
          setSaveVisitDefFn(() => saveNewVisit);
        } else {
          saveNewVisit();
        }
      } else {
        console.error("Invalid visit data");
      }
    }
  };

  return (
    <div className="text-primary-100">
      {visit.loading ? (
        <div>{t("visitWizard.loading")}</div>
      ) : (
        <form onSubmit={handleSubmit(saveVisit)}>
          <div className="rounded-2xl m-2 px-4 p-5 bg-greyscale-800">
            <div className="flex flex-col">
              <Label
                size="m"
                htmlFor={"care-recipient-select"}
                className="py-2 px-6"
              >
                {t("careRecipient")}
              </Label>
              {isNew && !visit.data?.visitById?.careRecipient ? (
                <Controller
                  control={control}
                  name="careRecipientId"
                  render={({ field }) => (
                    <Select
                      name="care-recipient-select"
                      options={careRecipientOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={careRecipientOptions.find(
                        (option) => option.value === field.value,
                      )}
                      onChange={(v) => field.onChange(v?.value)}
                      onBlur={field.onBlur}
                      isMulti={false}
                      errorMessage={
                        errors.careRecipientId?.message
                          ? t(errors.careRecipientId?.message)
                          : ""
                      }
                      errorMessageIcon={!!errors.careRecipientId}
                      isDisabled={!isNew}
                      aria-label="Care Recipient"
                    />
                  )}
                />
              ) : (
                <Label
                  size="m"
                  htmlFor={"Care Recipient"}
                  className="py-2 px-4"
                >
                  {getFullName(
                    visit?.data?.visitById?.careRecipient ?? {
                      firstName: "",
                      lastName: "",
                    },
                    nameOrder,
                  )}
                </Label>
              )}
            </div>
            {isNew && (
              <div className="pt-6 pb-2">
                <Tabs
                  tabs={[
                    { id: "now", label: t("visitWizard.now") },
                    { id: "schedule", label: t("visitWizard.schedule") },
                  ]}
                  currentTab={tab}
                  onChange={function (tab: string): void {
                    setValue("startNow", tab === "now");
                  }}
                />
              </div>
            )}
            {tab === "schedule" ? (
              <div className="flex flex-col gap-y-2">
                <FormGroupStacked label={t("visitWizard.date")}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <DatePicker
                        dateSettings={viewer?.tenantSettings}
                        className="!max-w-none"
                        aria-labelledby="startDateTime-label"
                        showCalendar="showCalendarOnFocus"
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value}
                        isInvalid={!!errors.date}
                      />
                    )}
                  />
                </FormGroupStacked>
                <FormGroupStacked label={t("visitWizard.startTime")}>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <TimePicker
                        className="pb-4"
                        dateSettings={viewer?.tenantSettings}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value}
                        errorMessage={
                          errors.startTime?.message
                            ? t(errors.startTime?.message)
                            : ""
                        }
                      />
                    )}
                  />
                </FormGroupStacked>
              </div>
            ) : null}
            <Controller
              control={control}
              name="duration"
              render={({ field }) => (
                <VisitDuration
                  {...field}
                  control={control}
                  dateSettings={viewer?.tenantSettings}
                  startTime={
                    selectedStartTime
                      ? DateTime.fromObject(selectedStartTime)
                      : undefined
                  }
                  errorMessage={
                    errors.duration?.message
                      ? t(errors.duration?.message)
                      : undefined
                  }
                />
              )}
            />
          </div>
          {/* At the moment we only support activities for new visits */}
          {isNew && activities.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="mx-4 pb-2 border-b border-greyscale-200 dark:border-greyscale-700">
                <div className="pt-14 px-2">
                  <Headline size="m">{t("activities")}</Headline>
                </div>
              </div>
              <div className="overflow-auto">
                <Checkboxes
                  control={control}
                  name="activities"
                  options={activities.map((activity) => ({
                    label: activity.title,
                    value: activity.id,
                    disabled: false,
                  }))}
                />
              </div>
            </div>
          )}
          <div className="fixed bottom-0 w-full pt-2">
            <div className="px-6 pt-2 pb-8 border-gray-60 bg-greyscale-800 flex flex-grow flex-row gap-3">
              <Button
                onClick={() => navigate(-1)}
                text={t("cancel")}
                variant="secondary"
                className="flex-1"
              />
              <Button type="submit" text={t("save")} className="flex-1" />
            </div>
          </div>
        </form>
      )}
      <VisitConflictModal
        show={conflict}
        confirm={saveVisitDefFn}
        cancel={() => setConflict(false)}
      />
    </div>
  );
};
