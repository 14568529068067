import { useTranslate } from "@tolgee/react";
import { useEffect, useState } from "react";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import SubmitFooter from "../../components/core/submitFooter/SubmitFooter";
import { useNavigate, useParams } from "react-router-dom";
import { CurrencyInput } from "../../components/common/currencyInput/CurrencyInput";

export const Expenses = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [expensesReason, setExpensesReason] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const { formatCurrency } = useNumberFormatter();

  useEffect(() => {
    const savedExpenses = Number(localStorage.getItem("expenses|" + id));
    const savedReason = localStorage.getItem("expenseReason|" + id) || "";

    setTotalExpenses(savedExpenses);
    setExpensesReason(savedReason);
  }, [id]);

  const handleSubmit = () => {
    localStorage.setItem("expenses|" + id, totalExpenses.toString());
    localStorage.setItem("expenseReason|" + id, expensesReason);
  };

  return (
    <div className="w-full h-full pt-8 pb-36 flex-col justify-start items-start gap-4 inline-flex">
      <div className="self-stretch h-16 flex-col justify-center items-center gap-2 flex">
        <label className="text-zinc-100 text-base font-normal font-poppins leading-snug">
          {t("expenses")}
        </label>
        <label className="text-zinc-100 text-3xl font-semibold font-poppins leading-10">
          {formatCurrency(totalExpenses)}
        </label>
      </div>
      <div className="flex-auto w-full h-60 p-4 flex-col justify-start gap-6 flex">
        <div className="h-24 flex-col justify-start items-start gap-3 flex">
          <label className="pl-4 self-stretch h-7 text-zinc-100 text-lg font-medium font-poppins leading-relaxed">
            {t("amount")}
          </label>
          <CurrencyInput
            placeholder="0"
            value={totalExpenses}
            onChange={(value) => setTotalExpenses(value)}
          />
        </div>
        <div className="flex-col justify-start items-start gap-3 flex">
          <label className="pl-4 self-stretch h-7 text-zinc-100 text-lg font-medium font-poppins leading-relaxed">
            {t("expenseReason")}
          </label>
          <textarea
            value={expensesReason}
            onChange={(event) => setExpensesReason(event.target.value)}
            className={`h-48 self-stretch px-4 py-3 text-zinc-100 bg-gray-900 rounded-xl outline-none border focus:border-indigo-700 focus:bg-black border-zinc-700 backdrop-blur-lg justify-start items-center gap-2 inline-flex ${
              showError ? "border-red-500" : ""
            }`}
            placeholder={t("expenseReason") ?? ""}
          />
          {showError && (
            <label className="w-80 pl-4 text-rose-300 text-sm font-medium font-poppins leading-tight">
              {t("expensesError")}
            </label>
          )}
        </div>
      </div>

      <SubmitFooter
        onSubmit={() => {
          if (expensesReason !== "") {
            handleSubmit();
            navigate(-1);
          } else {
            setShowError(true);
          }
        }}
      />
    </div>
  );
};
