import classNames from "classnames";

import LoadingSpinner from "../../assets/svg/loading-spinner.svg?react";
import { forwardRef } from "react";

type ChoiceChipButtonProps = {
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  ariaLabel?: string;
};

export const ChoiceChipButton = forwardRef<
  HTMLButtonElement,
  ChoiceChipButtonProps
>(function Button(
  {
    id,
    onClick,
    text,
    className,
    loading = false,
    disabled = false,
    type = "button",
    ariaLabel,
  },
  ref,
) {
  const commonClassNames =
    "relative inline-flex items-center justify-center font-medium focus:outline-none";
  const sizeClassNames = "rounded-xl text-base leading-normal py-3";

  return (
    <button
      id={id}
      type={type}
      ref={ref}
      className={classNames(
        commonClassNames,
        sizeClassNames,
        "dark:hover:ring-greyscale-500",
        {
          "text-gray-700 fill-greyscale-600 dark:text-greyscale-300 dark:fill-greyscale-500":
            disabled,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {loading && (
        <LoadingSpinner
        // center spinner if it's not replacing icon
        />
      )}
      {/* Use LeftIcon here */}
      <span
        className={classNames(
          { "text-transparent": loading },
          "whitespace-pre",
        )}
      >
        {text}
      </span>
    </button>
  );
});

type Choice = {
  id: string;
  text: string;
};

type ChoiceChipsProps = {
  choices: Choice[];
  selectedChoice?: Choice;
  onChange: (choice: Choice) => void;
};

export const ChoiceChips = forwardRef<HTMLDivElement, ChoiceChipsProps>(
  function ChoiceChips({ choices, selectedChoice, onChange }, ref) {
    return (
      <div ref={ref} className="flex flex-row gap-1">
        {choices.map((choice) => {
          return (
            <ChoiceChipButton
              key={choice.id}
              className={classNames(
                "my-1 bg-greyscale-700 rounded-lg basis-1/4 px-5",
                {
                  "bg-primary-400 dark:text-black": selectedChoice
                    ? choice.id === selectedChoice.id
                    : false,
                  "hover:bg-primary-400": selectedChoice
                    ? choice.id !== selectedChoice.id
                    : true,
                },
              )}
              text={choice.text}
              onClick={() => onChange(choice)}
            />
          );
        })}
      </div>
    );
  },
);
