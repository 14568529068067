import { useTranslate } from "@tolgee/react";
import { useEffect, useState } from "react";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import SubmitFooter from "../../components/core/submitFooter/SubmitFooter";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "@frontend/lyng/typography";

export const Mileage = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [mileages, setMileages] = useState<number[]>([0, 0]);
  const { distanceUnitShort, formatNumber } = useNumberFormatter();

  const handleMileageChange = (index: number, event: string) => {
    const newMileage = Number(event);
    if (!isNaN(newMileage)) {
      setMileages((prev) => [
        ...prev.slice(0, index),
        newMileage,
        ...prev.slice(index + 1),
      ]);
    }
  };

  useEffect(() => {
    const milesDrivenToAndFromLocation = Number(
      localStorage.getItem("milesDrivenToAndFromLocation|" + id),
    );
    const milesDrivenForClient = Number(
      localStorage.getItem("milesDrivenForClient|" + id),
    );

    setMileages([milesDrivenToAndFromLocation, milesDrivenForClient]);
  }, [id]);

  const totalMileage = mileages.reduce((acc, mileage) => acc + mileage, 0);

  const handleSubmit = () => {
    localStorage.setItem(
      "milesDrivenToAndFromLocation|" + id,
      mileages[0].toString(),
    );
    localStorage.setItem("milesDrivenForClient|" + id, mileages[1].toString());
  };

  return (
    <div className="w-full h-96 pt-8 pb-36 flex-col justify-start items-start gap-4 inline-flex">
      <div className="self-stretch h-16 flex-col justify-center items-center gap-2 flex">
        <label className="text-zinc-100 text-base font-normal font-poppins leading-snug">
          {t("mileage")}
        </label>
        <label className="text-zinc-100 text-3xl font-semibold font-poppins leading-10">
          {formatNumber(totalMileage)} {distanceUnitShort}
        </label>
      </div>
      <div className="flex-auto w-full h-60 p-4 flex-col justify-start gap-6 flex">
        {[
          "visitDetails.milesDrivenToAndFromLoacation",
          "visitDetails.milesDrivenForClient",
        ].map((label, index) => (
          <div
            key={index}
            className="h-24 flex-col justify-start items-start gap-3 flex"
          >
            <Label size="s" className="pl-3 h-auto text-zinc-100">
              {t(label)}
            </Label>
            <input
              value={mileages[index] === 0 ? "" : mileages[index]}
              onChange={(event) =>
                handleMileageChange(index, event?.target.value)
              }
              type="number"
              placeholder="0"
              className="self-stretch px-4 py-3 text-zinc-100 bg-gray-900 rounded-xl border outline-none focus:border-indigo-700 focus:bg-black  border-zinc-700 backdrop-blur-lg justify-start items-center gap-2 inline-flex"
            />
          </div>
        ))}
      </div>

      <SubmitFooter
        onSubmit={() => {
          handleSubmit();
          navigate(-1);
        }}
      />
    </div>
  );
};
