import Empty from "../assets/svg/empty";

type EmptyNotificationsProps = {
  unreadOnly: boolean;
  t: (key: string) => string;
};

export const EmptyNotifications = ({
  unreadOnly,
  t,
}: EmptyNotificationsProps) => {
  return (
    // should center vertically and horizontally
    <div className="flex flex-col items-center justify-center h-full mt-12 bg-primary-0 dark:bg-greyscale-900">
      <Empty.B1 />
      <p className="text-lg font-semibold text-greyscale-900 dark:text-greyscale-100">
        {unreadOnly
          ? t("emptyNotifications.noUnreadNotifications")
          : t("emptyNotifications.noNotifications")}
      </p>
    </div>
  );
};
