import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { match } from "ts-pattern";
import { CarelogResult, FeedLog, VisitLogResult } from "./types";
import { Button } from "../button";
import { useTranslate } from "@tolgee/react";
import { FeedItemsByCareRecipientIdQuery, FeedItemsQuery } from "./types";
import { ApolloError } from "@apollo/client";
import { ActivitiesFeedCard } from "./ActivitesFeedCard";
import { ActivityStatus } from "../api/generated/graphql";
import { DateSettings } from "../utils/dateUtils";

type IntersectionObserverProps = IntersectionObserverInit & {
  target: React.RefObject<HTMLElement>;
  onIntersect: () => void;
  enabled?: boolean;
};

const useIntersectionObserver = ({
  root = null,
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px",
  enabled = true,
}: IntersectionObserverProps) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    const { current } = target;
    if (current) {
      observer.observe(current);
    }

    return () => {
      observer.disconnect();
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};

interface ActivitiesFeedProps {
  data: FeedItemsQuery | FeedItemsByCareRecipientIdQuery | undefined;
  dateSettings: DateSettings;
  error: ApolloError | undefined;
  loading: boolean;
  cardClick: (id: string, type: string) => void;
}

const normalizeData = (
  data: FeedItemsQuery | FeedItemsByCareRecipientIdQuery | undefined,
) => {
  if (!data) return [];

  if ("feedItems" in data) {
    return data.feedItems.map((item) => ({
      ...item,
      source: "FeedItems",
      activities: item.__typename === "Visit" ? item.activities : [],
    }));
  }

  if ("feedItemsByCareRecipientId" in data) {
    return data.feedItemsByCareRecipientId.map((item) => ({
      ...item,
      source: "CareRecipient",
    }));
  }

  return [];
};

export const ActivitiesFeed: React.FC<ActivitiesFeedProps> = ({
  data,
  dateSettings,
  error,
  cardClick,
  loading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const lastCardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] = useState(false);
  const [logsToRender, setLogsToRender] = useState(20);
  const [showGoToTop, setShowGoToTop] = useState(false);
  useEffect(() => {
    if (containerRef.current) {
      setInfiniteScrollEnabled(
        containerRef.current.clientHeight < containerRef.current.scrollHeight,
      );

      const handleScroll = () => {
        if (containerRef.current) {
          const { scrollTop, clientHeight } = containerRef.current;
          setShowGoToTop(scrollTop > clientHeight / 2);
        }
      };

      const container = containerRef.current;
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    } else {
      setInfiniteScrollEnabled(false);
    }
  }, [containerRef, data]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (infiniteScrollEnabled && !loading) {
        setLogsToRender((prev) => prev + 20);
      }
    },
  });

  const createVisitLog = useCallback(
    (visit: VisitLogResult, source: string): FeedLog => {
      const visitorName = visit.visitors.length
        ? `${visit.visitors?.[0]?.firstName} ${visit.visitors?.[0]?.lastName}`
        : t("noCaregiver");
      const careRecipientName = `${visit.careRecipient.firstName} ${visit.careRecipient.lastName}`;
      const completedActivites = visit.activities.filter(
        (activity) => activity.status === ActivityStatus.Completed,
      );
      return {
        fullName: visitorName,
        id: visit.visitId,
        log: visit.visitNote ?? null,
        visitlogSentiment: visit.visitNoteSentiment ?? null,
        careRecipientId: visit.careRecipientId,
        type: "VISIT",
        logDate: visit.clockOutTime ?? null,
        careRecipientName: careRecipientName,
        source: source === "FeedItems" ? "FeedItems" : "CareRecipient",
        completedActivites: completedActivites.length ?? null,
        numberOfActivites: visit.activities.length ?? null,
      };
    },
    [t],
  );

  const createCarelog = useCallback(
    (carelog: CarelogResult, source: string): FeedLog => {
      const fullName = `${carelog.author?.firstName} ${carelog.author?.lastName}`;
      const careRecipientName = `${carelog.careRecipient?.firstName ?? ""} ${carelog.careRecipient?.lastName ?? ""}`;
      return {
        fullName,
        id: carelog.carelogId,
        log: carelog.log,
        visitlogSentiment: null,
        careRecipientId: carelog.careRecipient.id,
        type: "CARELOG",
        logDate: carelog.createdAt,
        careRecipientName,
        source: source === "FeedItems" ? "FeedItems" : "CareRecipient",
        completedActivites: null,
        numberOfActivites: null,
      };
    },
    [],
  );

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const myLogs = useMemo(() => {
    const normalizedData = normalizeData(data);

    if (!normalizedData) return [];

    return normalizedData
      .flatMap((item) =>
        match(item)
          .with({ __typename: "Visit" }, (visit) =>
            createVisitLog(visit, item.source),
          )
          .with({ __typename: "Carelog" }, (carelog) =>
            createCarelog(carelog, item.source),
          )
          .otherwise(() => []),
      )
      .slice(0, logsToRender);
  }, [data, createVisitLog, createCarelog, logsToRender]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div
      ref={containerRef}
      className="no-scrollbar flex flex-col overflow-y-auto h-[calc(100vh-70px)]"
    >
      {myLogs.map((log, index) => (
        <ActivitiesFeedCard
          dateSettings={dateSettings}
          key={log.id}
          log={log}
          onClick={cardClick}
          ref={index === myLogs.length - 1 ? lastCardRef : null}
          className={index === myLogs.length - 1 ? "border-none" : ""}
        />
      ))}
      <div ref={loadMoreRef} style={{ height: "20px" }} />
      {showGoToTop && (
        <Button
          size="md"
          variant="secondary"
          onClick={scrollToTop}
          className="bottom-4 p-2 place-self-end sticky bg-primary-0 dark:bg-greyscale-500"
          text={t(`goToTop`) || ""}
          disabled={false}
        />
      )}
    </div>
  );
};
