// Create a hook that will be used to show the Notifications prompt

import { useEffect, useState } from "react";

export const useNotificationsEnablePrompt = () => {
  const [shouldShowNotificationsPrompt, setShouldShowNotificationsPrompt] =
    useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (accepted) {
      localStorage.setItem("notificationsPromptAccepted", "true");
    }
  }, [accepted]);

  useEffect(() => {
    // Only trigger automatically once
    if (localStorage.getItem("automaticNotificationsPrompt")) return;

    setShouldShowNotificationsPrompt(true);

    localStorage.setItem("automaticNotificationsPrompt", "true");
  }, []);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("notificationsPromptAccepted");
    if (hasAccepted === "true") {
      setAccepted(true);
    }
  }, []);

  return {
    shouldShowNotificationsPrompt,
    setShouldShowNotificationsPrompt,
    setAccepted,
    accepted,
  } as const;
};
