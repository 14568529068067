import classNames from "classnames";
import ReactSelect, {
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  OptionProps,
  OptionsOrGroups,
  PropsValue,
  Props as ReactSelectProps,
  components,
} from "react-select";
import { match } from "ts-pattern";

import { Close } from "../../assets/icons/12/outline";
import { Checkmark } from "../../assets/icons/16/outline";
import { ChevronDown } from "../../assets/icons/24/filled";
import { Paragraph } from "../../typography";
import { ErrorMessage } from "../errorMessage/ErrorMessage";
import { useTranslate } from "@tolgee/react";

// Ensure Option and ValueContainer have the correct generic types

const Option = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => {
  const { children, isDisabled, ...rest } = props;
  return (
    <components.Option<Option, IsMulti, Group>
      {...rest}
      isDisabled={isDisabled}
    >
      <Paragraph size="m">{children}</Paragraph>
      {props.isSelected && !isDisabled && (
        <Checkmark className="text-greyscale-900 dark:text-greyscale-100" />
      )}
    </components.Option>
  );
};

const MultiValueRemove = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => {
  if (props.selectProps.isDisabled) {
    return null;
  }

  return (
    <components.MultiValueRemove {...props}>
      <Close className="text-greyscale-700 dark:text-greyscale-300" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  return (
    <components.DropdownIndicator<Option, IsMulti, Group> {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
};

type Props<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = ReactSelectProps<Option, IsMulti, Group> & {
  isMulti?: IsMulti;
  value: PropsValue<Option> | undefined;
  options: OptionsOrGroups<Option, Group>;
  formatGroupLabel?: (group: Group) => React.ReactNode;
  displayView?: boolean;
  errorMessage?: string;
  errorMessageIcon?: boolean;
  menuPlacement?: "top" | "bottom";
};

export const Select = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group>,
) => {
  const { t } = useTranslate();
  const {
    errorMessage = "",
    displayView = false,
    placeholder = t("select"),
    menuPlacement = "bottom",
    ...reactSelectProps
  } = props;

  return (
    <div className="flex flex-col gap-3">
      <ReactSelect<Option, IsMulti, Group>
        {...reactSelectProps}
        placeholder={displayView ? "-" : placeholder}
        isDisabled={props.isDisabled || displayView}
        unstyled
        className=""
        classNames={{
          control: (state) =>
            classNames(
              "flex w-full !transition-colors text-greyscale-900 text-lg dark:text-greyscale-100",
              state.menuIsOpen && menuPlacement === "bottom"
                ? "rounded-t-xl border-t border-l border-r"
                : "border rounded-xl",
              state.menuIsOpen && menuPlacement === "top"
                ? "rounded-b-xl border-b border-l border-r"
                : "border rounded-xl",
              {
                "border-t-0 rounded-t-none":
                  state.isFocused &&
                  state.menuIsOpen &&
                  menuPlacement === "top",

                "border-b-0 rounded-b-none":
                  state.isFocused &&
                  state.menuIsOpen &&
                  menuPlacement === "bottom",
              },
              match({
                focused: state.isFocused || state.menuIsOpen,
                errorMessage: !!errorMessage,
                displayView: displayView,
              })
                .with(
                  { displayView: true },
                  () => "border-transparent bg-transparent",
                )
                .with(
                  { focused: true },
                  () =>
                    "border-primary-600 bg-greyscale-0 dark:bg-greyscale-900",
                )
                .with(
                  { focused: false, errorMessage: true },
                  () =>
                    "border-critical-600 bg-primary-0 hover:bg-greyscale-0 dark:bg-greyscale-800 dark:hover:bg-greyscale-900",
                )
                .with(
                  { focused: false, errorMessage: false },
                  () =>
                    "border-greyscale-200 hover:border-greyscale-400 bg-transparent hover:bg-greyscale-0 dark:border-greyscale-700 dark:hover:border-greyscale-500 dark:hover:bg-greyscale-900 ",
                )
                .exhaustive(),
              {
                "from-greyscale-0 dark:from-greyscale-900":
                  state.isFocused || state.menuIsOpen,
                "from-greyscale-0 dark:from-greyscale-800 hover:dark:from-greyscale-900":
                  !state.isFocused && !state.menuIsOpen,
              },
            ),
          valueContainer: (_state) =>
            classNames(
              "flex gap-2 min-h-12",
              props.isMulti ? "py-2 pl-2" : "py-2.5 pl-4",
            ),
          indicatorsContainer: (_state) =>
            displayView ? "!hidden" : "pr-4 !items-start",
          input: (_state) => (displayView ? "!hidden" : ""),
          placeholder: (_state) =>
            classNames("text-greyscale-700 dark:text-greyscale-300 pr-1.5", {
              // Is multi placeholder needs more padding because the pills take more space
              "pl-2": props.isMulti,
            }),
          menu: (_state) =>
            classNames(
              "border border-primary-600 !z-50 bg-greyscale-0 dark:bg-greyscale-900 overflow-hidden",
              {
                "rounded-b-xl border-t-0": menuPlacement === "bottom",
                "rounded-t-xl border-b-0": menuPlacement === "top",
              },
            ),
          menuList: (_state) => "flex flex-col gap-1 p-1",
          option: (state) =>
            classNames(
              "!flex flex-row items-center justify-between p-3 rounded-lg",
              {
                "bg-primary-50 dark:bg-primary-800":
                  state.isFocused && !state.isSelected,
                "bg-primary-100 dark:bg-primary-600": state.isSelected,
              },
            ),
          dropdownIndicator: (state) =>
            classNames(
              "flex items-center h-12 text-primary-900 !transition-transform dark:text-greyscale-100",
              {
                "-rotate-180": state.selectProps.menuIsOpen,
              },
            ),
          clearIndicator: (_state) => "flex items-center h-12",
          multiValue: (_state) =>
            "w-full max-w-fit flex gap-1.5 !min-w-[initial] pl-3.5 pr-2.5 py-1 bg-greyscale-100 rounded-full dark:bg-primary-800",
          multiValueLabel: (_state) => "text-base font-inclusive ",
        }}
        components={{
          Option: (props) => <Option {...props} />,
          DropdownIndicator: (props) => <DropdownIndicator {...props} />,
          MultiValueRemove: (props) => <MultiValueRemove {...props} />,
        }}
        menuPlacement={menuPlacement}
      />
      {errorMessage && !displayView && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
};
