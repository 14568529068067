import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  CalendarDate,
  DateDuration,
  createCalendar,
  endOfMonth,
  getDayOfWeek,
  getWeeksInMonth,
  isSameDay,
} from "@internationalized/date";
import { AriaButtonOptions, useButton } from "@react-aria/button";
import {
  AriaCalendarGridProps,
  AriaCalendarProps,
  AriaRangeCalendarProps,
  DateValue,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useRangeCalendar,
} from "@react-aria/calendar";
import { useFocusRing } from "@react-aria/focus";
import { useLocale } from "@react-aria/i18n";
import { merge } from "lodash";
import { useEffect, useRef } from "react";
import {
  CalendarState,
  RangeCalendarState,
  useCalendarState,
  useRangeCalendarState,
} from "react-stately";

export function Calendar(
  props: AriaCalendarProps<DateValue> & { country: string },
) {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    locale,
    visibleDuration: { months: 1 },
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <div {...calendarProps} className="inline-block text-gray-800">
      <div className="flex items-center pb-4">
        <h2 className="ml-2 flex-1 text-xl font-bold">{title}</h2>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon className="h-6 w-6" />
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon className="h-6 w-6" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} country={props.country} />
    </div>
  );
}
export function RangeCalendar(
  props: AriaRangeCalendarProps<DateValue> & { country: string },
) {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  return (
    <div {...calendarProps} ref={ref} className="inline-block">
      <div className="flex items-center pb-4">
        <h2 className="ml-2 flex-1 text-xl font-bold">{title}</h2>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon className="h-6 w-6" />
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon className="h-6 w-6" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} country={props.country} />
    </div>
  );
}

type CalendarButtonProps = AriaButtonOptions<"button"> & {
  isDisabled?: boolean;
  children: React.ReactNode;
};
function CalendarButton(props: CalendarButtonProps) {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...merge(buttonProps, focusProps)}
      ref={ref}
      className={`rounded-full p-2 ${props.isDisabled ? "text-gray-400" : ""} ${
        !props.isDisabled ? "hover:bg-primary-100 active:bg-primary-200" : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-purple-600 ring-offset-2" : ""
      }`}
    >
      {props.children}
    </button>
  );
}

type CalendarGridProps = Omit<
  AriaCalendarGridProps,
  "startDate" | "endDate"
> & {
  country: string;
  state: CalendarState | RangeCalendarState;
  offset?: DateDuration;
};

function CalendarGrid({
  state,
  offset = {},
  country,
  ...props
}: CalendarGridProps) {
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);

  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    { ...props, startDate, endDate },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, country ?? "");

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type CalendarCellProps = {
  state: CalendarState | RangeCalendarState;
  date: CalendarDate;
};
function CalendarCell({ state, date }: CalendarCellProps) {
  const { locale } = useLocale();

  const ref = useRef<HTMLDivElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart =
    "highlightedRange" in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;
  const isSelectionEnd =
    "highlightedRange" in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  // Workaround to prevent touchstart leaking to underlying elements:
  // https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1172267250
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current?.addEventListener("touchstart", (event: TouchEvent) => {
      event.preventDefault();
    });
  }, []);

  return (
    <td
      {...cellProps}
      className={`relative py-0.5 ${isFocusVisible ? "z-10" : "z-0"}`}
    >
      <div
        {...merge(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={`group h-10 w-10 outline-none ${
          isRoundedLeft ? "rounded-l-full" : ""
        } ${isRoundedRight ? "rounded-r-full" : ""} ${
          isSelected ? (isInvalid ? "bg-red-300" : "bg-primary-100") : ""
        } ${isDisabled ? "disabled" : ""}`}
      >
        <div
          className={`flex h-full w-full items-center justify-center rounded-full ${
            isDisabled && !isInvalid ? "text-gray-400" : ""
          } ${
            // Focus ring, visible while the cell has keyboard focus.
            isFocusVisible
              ? "group-focus:z-2 ring-2 ring-primary-600 ring-offset-2"
              : ""
          } ${
            // Darker selection background for the start and end.
            isSelectionStart || isSelectionEnd
              ? isInvalid
                ? "bg-red-600 text-white hover:bg-red-700"
                : "bg-primary-500 text-white hover:bg-primary-700"
              : ""
          } ${
            // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? isInvalid
                ? "hover:bg-red-400"
                : "hover:bg-primary-400"
              : ""
          } ${
            // Hover state for non-selected cells.
            !isSelected && !isDisabled ? "hover:bg-primary-100" : ""
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
