import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Modal from "@frontend/lyng/modal/Modal";
import { Paragraph } from "@frontend/lyng/typography";
import { Button } from "@frontend/lyng/button";
import { Radios } from "@frontend/lyng/forms";
import { useTranslate } from "@tolgee/react";

type LateClockOutModalProps = {
  dismiss: () => void;
  save: (values: LateClockOutForm) => Promise<void>;
  show: boolean;
};

export type LateClockOutOption = "now" | "scheduled";
export type LateClockOutForm = {
  clockOutOption?: LateClockOutOption;
};

const lateClockOutSchema = z.object({
  clockOutOption: z.string(),
});

export const LateClockOutModal = ({
  dismiss,
  save,
  show,
}: LateClockOutModalProps) => {
  const { t } = useTranslate();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<LateClockOutForm>({
    resolver: zodResolver(lateClockOutSchema),
    defaultValues: {
      clockOutOption: undefined,
    },
  });

  const innerOnSubmit = async (values: LateClockOutForm) => {
    await save(values);
    reset({}, { keepValues: false });
    dismiss();
  };

  return (
    <Modal show={show}>
      <form onSubmit={handleSubmit(innerOnSubmit)}>
        <Modal.Title>{t("lateClockOutModal.title")}</Modal.Title>
        <Paragraph className="mt-10 mb-2">
          {t("lateClockOutModal.description")}
        </Paragraph>
        <Radios
          name="clockOutOption"
          control={control}
          options={[
            { label: t("lateClockOutModal.now"), value: "now" },
            { label: t("lateClockOutModal.scheduled"), value: "scheduled" },
          ]}
          disabled={false}
          errorMessage={errors.clockOutOption && t("lateClockOutModal.error")}
        />
        <Modal.Footer>
          <div className="flex flex-row justify-center items-center">
            <Button
              variant="secondary"
              disabled={false}
              text={t("cancel")}
              className="mb-1 mr-2 flex-grow"
              onClick={() => {
                reset();
                dismiss();
              }}
            />
            <Button
              disabled={false}
              type="submit"
              text={t("continue")}
              className="mb-1 flex-grow"
            />
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
