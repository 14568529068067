import { useMemo } from "react";
import { NameOrder, TenantSettings } from "../../api/generated/graphql";
import { match } from "ts-pattern";

export const useSortingOptions = (
  tenantSettings?: Pick<TenantSettings, "nameOrder" | "country">,
) => {
  return useMemo(() => {
    const nameOrder = tenantSettings?.nameOrder || NameOrder.LastFirst;
    const nameOrderFn = ({
      firstName,
      lastName,
    }: {
      firstName: string | null;
      lastName: string | null;
    }) =>
      match(nameOrder)
        .with(NameOrder.FirstLast, () => `${firstName ?? ""} ${lastName ?? ""}`)
        .with(
          NameOrder.LastFirst,
          () => `${lastName ?? ""}, ${firstName ?? ""}`,
        )
        .exhaustive();

    const country = tenantSettings?.country;
    const collator = new Intl.Collator(country);

    return { nameOrder, nameOrderFn, collator };
  }, [tenantSettings?.nameOrder, tenantSettings?.country]);
};
