import { useNavigate, useParams } from "react-router";
import {
  useFeedItemsByCareRecipientIdQuery,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { Headline } from "@frontend/lyng/typography";
import { ActivitiesFeed } from "@frontend/lyng/activityFeed";
import { VisitLogResult } from "@frontend/lyng/activityFeed/types";
import { DateTime } from "luxon";
import { useCareContext } from "../../providers";
import Leaves from "../../assets/leaves.svg?react";
import { Spinner } from "../../components/common";

export const PastVisits = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });
  const careRecipient = data?.visitById?.careRecipient;
  const { viewer } = useCareContext().state;
  const {
    data: feedData,
    error: feedError,
    loading: feedLoading,
  } = useFeedItemsByCareRecipientIdQuery({
    fetchPolicy: "network-only",
    variables: {
      from: DateTime.local().endOf("day").toISO() ?? "",
      limit: 60,
      careRecipientId: careRecipient?.id ?? "",
    },
  });

  const handleCardClick = (id: string, type: string) => {
    navigate(`/visit/${id}`);
  };

  const cleanLogs = () => {
    return feedData?.feedItemsByCareRecipientId.filter(
      (item): item is VisitLogResult => item.__typename === "Visit",
    );
  };
  const filteredFeedData = {
    ...feedData,
    feedItemsByCareRecipientId: cleanLogs() || [],
  };

  if (feedLoading || loading || !feedData || !data) return <Spinner />;
  if (feedError || error) return <>Error loading past visit data</>;

  return (
    <div>
      <Headline size="l" className=" text-white pl-4 flex">
        {<Leaves className=" mr-2"></Leaves>}
        {`${careRecipient?.firstName} ${careRecipient?.lastName}`}
      </Headline>
      <ActivitiesFeed
        dateSettings={viewer?.tenantSettings}
        data={filteredFeedData}
        loading={feedLoading}
        error={feedError}
        cardClick={handleCardClick}
      ></ActivitiesFeed>
    </div>
  );
};
