/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NumberFormat as PolyfillNumberFormat } from "@formatjs/intl-numberformat";

const LOCALES_THAT_ARE_POLYFILLED = ["is", "IS", "is-IS"];

const OriginalNumberFormat = Intl.NumberFormat;

const DynamicNumberFormat = new Proxy(PolyfillNumberFormat, {
  construct(target, args) {
    if (LOCALES_THAT_ARE_POLYFILLED.includes(args[0])) {
      return new target(...args);
    } else {
      return new OriginalNumberFormat(...args);
    }
  },
  apply(target, _, args) {
    if (LOCALES_THAT_ARE_POLYFILLED.includes(args[0])) {
      return target(...args);
    } else {
      return OriginalNumberFormat(...args);
    }
  },
  get(target, prop) {
    if (prop === "supportedLocalesOf") {
      return (
        value: string | string[],
        options?: Pick<Intl.NumberFormatOptions, "localeMatcher">,
      ) => {
        if (typeof value === "string") {
          if (LOCALES_THAT_ARE_POLYFILLED.includes(value)) {
            // @ts-ignore
            return target.supportedLocalesOf(value, options);
          } else {
            return OriginalNumberFormat.supportedLocalesOf(value, options);
          }
        } else {
          return value.map((locale) => {
            if (LOCALES_THAT_ARE_POLYFILLED.includes(locale)) {
              // @ts-ignore
              return target.supportedLocalesOf(locale, options)[0];
            } else {
              return OriginalNumberFormat.supportedLocalesOf(
                locale,
                options,
              )[0];
            }
          });
        }
      };
    } else {
      // @ts-ignore
      return target[prop];
    }
  },
});

Object.defineProperty(Intl, "NumberFormat", {
  value: DynamicNumberFormat,
  configurable: true,
  enumerable: false,
  writable: true,
});

// need to be statically defined for bundlers
Promise.all([import("@formatjs/intl-numberformat/locale-data/is")]);

const OriginalCollator = Intl.Collator;

// Custom Icelandic collation fallback logic
class CustomIcelandicCollator {
  map: Map<string, number>;
  originalCollator: Intl.Collator;

  constructor() {
    this.map = new Map();
    this.originalCollator = new OriginalCollator("is");
    const icelandicAlphabet =
      "!@#$%^&*()_+-=[]{}/?.,<>0123456789aábcdðeéfghiíjklmnoópqrstuúvwxyýzþæö";
    for (let i = 0; i < icelandicAlphabet.length; i++) {
      this.map.set(icelandicAlphabet[i], i + 1);
    }
  }

  // compare(x: string, y: string): number;
  // resolvedOptions(): ResolvedCollatorOptions;
  compare(a: string, b: string): number {
    a = a.toLowerCase();
    b = b.toLowerCase();

    for (let i = 0; i < Math.min(a.length, b.length); i++) {
      const posA = this.map.get(a[i]) || 0;
      const posB = this.map.get(b[i]) || 0;
      if (posA !== posB) {
        return posA - posB;
      }
    }

    return a.length - b.length;
  }
  resolvedOptions() {
    return this.originalCollator.resolvedOptions();
  }
}

// Proxy to intercept calls to Intl.Collator
const DynamicCollator = new Proxy(Intl.Collator, {
  construct: function (target, args) {
    const [locale] = args;

    if (
      LOCALES_THAT_ARE_POLYFILLED.includes(locale) &&
      OriginalCollator.supportedLocalesOf(locale).length === 0
    ) {
      // If not supported, return the custom Icelandic collator
      return new CustomIcelandicCollator();
    }

    // For other locales, use the built-in Intl.Collator
    return new target(...args);
  },
  apply: function (target, _, args) {
    const [locale] = args;

    if (
      LOCALES_THAT_ARE_POLYFILLED.includes(locale) &&
      OriginalCollator.supportedLocalesOf(locale).length === 0
    ) {
      // If not supported, return the custom Icelandic collator
      return new CustomIcelandicCollator();
    }

    // For other locales, use the built-in Intl.Collator
    return target(...args);
  },
  get: function (target, prop) {
    if (prop === "supportedLocalesOf") {
      return (
        value: string | string[],
        options?: Pick<Intl.CollatorOptions, "localeMatcher">,
      ) => {
        if (typeof value === "string") {
          if (LOCALES_THAT_ARE_POLYFILLED.includes(value)) {
            return ["is"];
          } else {
            return target.supportedLocalesOf(value, options);
          }
        } else {
          return value.map((locale) => {
            if (LOCALES_THAT_ARE_POLYFILLED.includes(locale)) {
              return "is";
            } else {
              return target.supportedLocalesOf(locale, options)[0];
            }
          });
        }
      };
    } else {
      // @ts-ignore
      return target[prop];
    }
  },
});

// Override Intl.Collator globally using Object.defineProperty
Object.defineProperty(Intl, "Collator", {
  value: DynamicCollator,
  enumerable: false,
  writable: true,
  configurable: true,
});
