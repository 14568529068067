import classNames from "classnames";
import { forwardRef } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegisterReturn,
  useController,
} from "react-hook-form";
import { Paragraph } from "../../typography";

import { default as Checked } from "./checked.svg?react";
import { default as Unchecked } from "./unchecked.svg?react";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

type Props = {
  id?: string;
  value?: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
};

export const Radio = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function Radio(
  { name, onChange, onBlur, value, label, checked, disabled },
  ref,
) {
  return (
    <label className="group flex items-center">
      <div className="flex items-center justify-center h-12 w-12">
        <div
          className={classNames(
            "relative flex items-center justify-center rounded-full h-8 w-8",
            "focus-within:h-10 focus-within:w-10 focus-within:bg-primary-200 dark:focus-within:bg-transparent",
            !disabled &&
              "group-hover:bg-gradient-radial-50% from-[rgba(109,118,243,0.00)] from-30% via-[rgba(158,176,250,0.25)] to-[rgba(200,211,254,0.00)] to-100%",
          )}
        >
          <input
            className="opacity-0"
            ref={ref}
            name={name}
            type="radio"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            checked={checked}
            disabled={disabled}
          />
          <div className="absolute">
            {checked ? (
              <Checked
                className={disabled ? "text-greyscale-700" : "text-primary-600"}
              />
            ) : (
              <Unchecked
                className={classNames(
                  "text-greyscale-700 dark:text-greyscale-300",
                  disabled ? "text-opacity-35" : "group-hover:text-primary-600",
                )}
              />
            )}
          </div>
        </div>
      </div>
      <Paragraph size="s">{label}</Paragraph>
    </label>
  );
});

type RadiosProps = {
  options: { label: string; value: string; disabled?: boolean }[];
  errorMessage?: string;
  disabled?: boolean;
};

export function Radios<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName> & RadiosProps) {
  const { field } = useController(props);

  return (
    <div className="flex flex-col gap-3">
      {props.options.map((option) => (
        <Radio
          id={`${field.name}-${option.value}`}
          key={`${field.name}-${option.value}`}
          name={field.name}
          onChange={(e) => {
            field.onChange(e.target.value);
            return Promise.resolve();
          }}
          onBlur={() => Promise.resolve(field.onBlur())}
          label={option.label}
          value={option.value}
          checked={option.value === field.value}
          disabled={option.disabled || props.disabled}
        />
      ))}
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </div>
  );
}
