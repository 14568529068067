import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GradientButton } from "../../components/core";
import { useTranslate } from "@tolgee/react";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { VisitInfo } from "../../types";

const ExpenseSummary = ({ visit }: { visit: VisitInfo }) => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { id } = useParams();
  const [totalMileage, setTotalMileage] = useState<number | null>(
    visit.mileage,
  );
  const [totalExpenses, setTotalExpenses] = useState<number | null>(
    visit.expenses,
  );
  const { formatCurrency, distanceUnitShort } = useNumberFormatter();

  useEffect(() => {
    const getValue = (key: string) => Number(localStorage.getItem(key));

    !visit.expenses && setTotalExpenses(getValue("expenses|" + id));
    !visit.mileage &&
      setTotalMileage(
        getValue("milesDrivenToAndFromLocation|" + id) +
          getValue("milesDrivenForClient|" + id),
      );
  }, [id, visit.expenses, visit.mileage]);

  const renderSummaryBlock = (
    label: string,
    value: number | null,
    onClick: () => void,
  ) => {
    const buttonText =
      value !== null && value !== undefined
        ? t("expenseSummary.edit")
        : t("expenseSummary.add");
    const formattedValue = value !== null ? value : 0;

    return (
      <div className="grow basis-0 px-2 flex-col justify-center items-center gap-4 inline-flex">
        <div className="self-stretch h-16 flex-col justify-center items-center gap-2 flex">
          <label className="text-zinc-100 text-base font-normal font-geologica leading-snug">
            {t(label)}
          </label>
          <label className="text-zinc-100 text-3xl font-semibold font-geologica leading-10">
            {label === "expenses"
              ? formatCurrency(formattedValue)
              : `${formattedValue} ${distanceUnitShort}`}
          </label>
        </div>
        {visit.status !== "clockedOut" && (
          <GradientButton
            buttonType="secondary"
            buttonSize="medium"
            text={buttonText}
            onClick={onClick}
          />
        )}
      </div>
    );
  };

  return (
    <div className="h-52 p-4 flex-col justify-start items-center gap-2 inline-flex">
      <div className="self-stretch py-6 border-t border-zinc-700 justify-center items-start gap-2 inline-flex">
        {renderSummaryBlock("mileage", totalMileage, () =>
          navigate("./mileage"),
        )}
        {renderSummaryBlock("expenses", totalExpenses, () =>
          navigate("./expenses"),
        )}
      </div>
    </div>
  );
};

export default ExpenseSummary;
