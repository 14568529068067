import {
  BackendFetch,
  DevTools,
  FormatSimple,
  LanguageDetector,
  Tolgee,
} from "@tolgee/react";

const initI18n = () => {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(LanguageDetector())
    .use(FormatSimple())
    .use(
      BackendFetch({
        getPath: ({ language, namespace }) => {
          return `/locales/${language}/${namespace}.json`;
        },
      }),
    )
    .init({
      // language: "en",
      availableLanguages: ["en", "is"],
      defaultLanguage: "en",
      fallbackLanguage: "en",

      defaultNs: "translation",
      // for development
      projectId: 2392,
      apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
    });
  return tolgee;
};

export default initI18n;
