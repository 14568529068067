import { match } from "ts-pattern";
import AddVisitNoteIcon from "../../assets/add-visit-note.svg?react";
import EditVisitNoteIcon from "../../assets/edit-visit-note.svg?react";
import { UseTranslateResult, useTranslate } from "@tolgee/react";

type NoteState = "add" | "edit";

type Props = {
  state: NoteState;
  onClick: () => void;
};

const getVisitNoteStateText = (
  state: NoteState,
  t: UseTranslateResult["t"],
) => {
  return match(state)
    .with("add", () => t("visitDetails.addVisitNote"))
    .with("edit", () => t("visitDetails.editVisitNote"))
    .exhaustive();
};

const getVisitNoteStateIcon = (state: NoteState) => {
  return match(state)
    .with("add", () => <AddVisitNoteIcon />)
    .with("edit", () => <EditVisitNoteIcon />)
    .exhaustive();
};

export const VisitNoteButton = ({ state, onClick }: Props) => {
  const { t } = useTranslate();
  return (
    <div
      className={`flex-1 h-fit bg-gray-60 overflow-hidden rounded-xl p-[1px] gap-2`}
    >
      <button
        className="flex h-full w-full items-center bg-main text-sm text-btn-label justify-center rounded-xl font-poppins font-bold px-4 py-3"
        onClick={onClick}
      >
        {getVisitNoteStateIcon(state)}
        <p className="pl-2">{getVisitNoteStateText(state, t)}</p>
      </button>
    </div>
  );
};
