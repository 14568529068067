import { Label } from "@frontend/lyng/typography";
import { VisitInfoWithActivities } from "../../utils/visits";
import {
  calculateVisitDuration,
  getRemainingTime,
} from "../../utils/dateUtils";
import { UseTranslateResult, useTranslate } from "@tolgee/react";

type Props = {
  visit: VisitInfoWithActivities;
};

type visitTimeline = {
  when: string;
  remainingTime: string;
};

const calculateVisitTimeline = (
  visit: VisitInfoWithActivities,
  t: UseTranslateResult["t"],
): visitTimeline => {
  const remainingTime = getRemainingTime(visit.to, visit.from, visit.status, t);
  const visitDuration = calculateVisitDuration(visit.from, visit.to, t);

  return {
    when: visitDuration,
    remainingTime,
  };
};

export const Title = ({ visit }: Props) => {
  const { t } = useTranslate();
  const visitTimeline = calculateVisitTimeline(visit, t);
  return (
    <div className="flex flex-col pt-2">
      <Label size="xs">{visitTimeline.when}</Label>
      <Label size="xxs" variant="secondary" className="text-greyscale-400">
        {visitTimeline.remainingTime}
      </Label>
    </div>
  );
};
