import classNames from "classnames";

type Props = {
  text: string;
  className?: string;
};

export const Subtext = ({ text, className }: Props) => (
  <div
    className={classNames(
      "text-sm text-gray-500 dark:text-greyscale-400 mt-1",
      className,
    )}
  >
    {text}
  </div>
);
